import type { AppType } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import "tailwindcss/tailwind.css";
import { z } from "zod";
import { Background, LoadSounds } from "@sunblocks/components";
import { LocalStorage, useScopedLocalStorage } from "src/components/use-local-storage";
import { SessionStorage } from "src/components/use-session-storage";
import "src/superjson";
import { trpc } from "src/trpc/client";
import type { serverTrpc } from "src/trpc/server";
import { VERCEL_PROJECT_PRODUCTION_URL } from "src/vercel/env";
const {
  NEXT_PUBLIC_HASH_ASSETS
} = z.object({
  NEXT_PUBLIC_HASH_ASSETS: z.string().min(1)
}).parse({
  NEXT_PUBLIC_HASH_ASSETS: process.env.NEXT_PUBLIC_HASH_ASSETS
});
if (typeof window !== "undefined") {
  const {
    NEXT_PUBLIC_POSTHOG_KEY
  } = z.object({
    NEXT_PUBLIC_POSTHOG_HOST: z.optional(z.string()),
    NEXT_PUBLIC_POSTHOG_KEY: z.optional(z.string())
  }).parse({
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY
  });
  if (NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
      // https://posthog.com/docs/advanced/proxy/nextjs
      api_host: "/ingest",
      // TODO Use new environment variables here
      ui_host: "https://us.posthog.com",
      debug: VERCEL_PROJECT_PRODUCTION_URL !== document.location.host,
      opt_out_capturing_by_default: VERCEL_PROJECT_PRODUCTION_URL !== document.location.host
    });
  }
}
export type PageProps = {
  trpcState?: ReturnType<ReturnType<typeof serverTrpc>["dehydrate"]>;
};
const App = (({
  Component,
  pageProps
}) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);
  return <>
      <Head>
        <title>Sun Blocks</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Head>
      <PostHogProvider client={posthog}>
        <LocalStorage>
          <SessionStorage>
            <LoadSounds prefix="/assets" suffix={NEXT_PUBLIC_HASH_ASSETS} useMuted={() => useScopedLocalStorage(({
            muted = false
          } = {}) => muted, (stored, muted) => ({
            ...stored,
            muted
          }))}>
              <Background>
                <Component key={router.asPath} {...pageProps} />
              </Background>
            </LoadSounds>
          </SessionStorage>
        </LocalStorage>
      </PostHogProvider>
    </>;
}) satisfies AppType<PageProps>;
export default trpc.withTRPC(App);