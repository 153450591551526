import { useMotionValueEvent, useSpring } from "framer-motion";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import useSound from "use-sound";
import type { HookOptions, PlayFunction } from "use-sound/dist/types";
import { useDocumentVisibility } from "./use-document-visibility-state";
import { useWindowFocus } from "./use-window-focus";
type Boolean16 = [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
const false16 = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] as Boolean16;
type Play16 = [PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction, PlayFunction];
const default16 = [() => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}] satisfies Play16;
const defaults: {
  loaded: {
    all: boolean;
    playBlockDrop: boolean;
    playBlockDropImmobile: boolean;
    playBlockLift: boolean;
    playBlockSun: Boolean16;
    playCellMove: boolean;
    playCellSun: Boolean16;
    playLevelAvailable: boolean;
    playLevelBackground: {
      default: boolean;
      fire: boolean;
      night: boolean;
    };
    playLevelDoneFull: boolean;
    playNActive: boolean;
    playNDisactive: boolean;
    playNPlusOne: boolean;
    playNodeFire: boolean;
    playNodeWater: boolean;
    playSinkSatisfied: boolean;
    playSinkSatisfiedEnd: boolean;
    playSinkSatisfiedEndFull: boolean;
    playSinkSatisfiedWater: boolean;
    playWeakActive: boolean;
    playWeakDisactive: boolean;
  };
  muted: boolean;
  playBlockDrop: PlayFunction;
  playBlockDropImmobile: PlayFunction;
  playBlockLift: PlayFunction;
  playBlockSun: Play16;
  playCellMove: PlayFunction;
  playCellSun: Play16;
  playLevelAvailable: PlayFunction;
  playLevelDoneFull: PlayFunction;
  playNActive: PlayFunction;
  playNDisactive: PlayFunction;
  playNPlusOne: PlayFunction;
  playNodeFire: PlayFunction;
  playNodeWater: PlayFunction;
  playSinkSatisfied: PlayFunction;
  playSinkSatisfiedEnd: PlayFunction;
  playSinkSatisfiedEndFull: PlayFunction;
  playSinkSatisfiedWater: PlayFunction;
  playWeakActive: PlayFunction;
  playWeakDisactive: PlayFunction;
  setLevelBackgroundPlaying: (levelBackground: "default" | "fire" | "night" | undefined) => void;
  setMuted: Dispatch<SetStateAction<boolean>>;
} = {
  muted: false,
  playBlockDrop: () => {},
  playBlockDropImmobile: () => {},
  playBlockLift: () => {},
  playBlockSun: default16,
  playCellMove: () => {},
  playCellSun: default16,
  playLevelAvailable: () => {},
  playLevelDoneFull: () => {},
  playNActive: () => {},
  playNDisactive: () => {},
  playNodeFire: () => {},
  playNodeWater: () => {},
  playNPlusOne: () => {},
  playSinkSatisfied: () => {},
  playSinkSatisfiedEnd: () => {},
  playSinkSatisfiedEndFull: () => {},
  playSinkSatisfiedWater: () => {},
  playWeakActive: () => {},
  playWeakDisactive: () => {},
  setMuted: () => {},
  setLevelBackgroundPlaying: () => {},
  loaded: {
    all: false,
    playBlockDrop: false,
    playBlockDropImmobile: false,
    playBlockLift: false,
    playBlockSun: false16,
    playCellMove: false,
    playCellSun: false16,
    playLevelAvailable: false,
    playLevelBackground: {
      default: false,
      fire: false,
      night: false
    },
    playLevelDoneFull: false,
    playNActive: false,
    playNDisactive: false,
    playNodeFire: false,
    playNodeWater: false,
    playNPlusOne: false,
    playSinkSatisfied: false,
    playSinkSatisfiedEnd: false,
    playSinkSatisfiedEndFull: false,
    playSinkSatisfiedWater: false,
    playWeakActive: false,
    playWeakDisactive: false
  }
};
const Sounds = createContext(defaults);
export const useSounds = (enabled = true) => {
  const context = useContext(Sounds);
  return enabled ? context : defaults;
};
export const LoadSounds = ({
  children,
  prefix = "",
  suffix = "",
  useMuted = () => useState(false)
}: {
  children: ReactNode;
  prefix?: string;
  suffix?: string;
  useMuted?: () => [boolean, Dispatch<SetStateAction<boolean>>];
}) => {
  const [volume, setVolume] = useState(1);
  const options = useMemo(() => ({
    volume,
    interrupt: true
  }) satisfies HookOptions, [volume]);
  const [playBlockSun1, {
    sound: soundBlockSun1
  }] = useSound(`${prefix}/block-sun-1.wav?${suffix}`, options);
  const [playBlockSun2, {
    sound: soundBlockSun2
  }] = useSound(`${prefix}/block-sun-2.wav?${suffix}`, options);
  const [playBlockSun3, {
    sound: soundBlockSun3
  }] = useSound(`${prefix}/block-sun-3.wav?${suffix}`, options);
  const [playBlockSun4, {
    sound: soundBlockSun4
  }] = useSound(`${prefix}/block-sun-4.wav?${suffix}`, options);
  const [playBlockSun5, {
    sound: soundBlockSun5
  }] = useSound(`${prefix}/block-sun-5.wav?${suffix}`, options);
  const [playBlockSun6, {
    sound: soundBlockSun6
  }] = useSound(`${prefix}/block-sun-6.wav?${suffix}`, options);
  const [playBlockSun7, {
    sound: soundBlockSun7
  }] = useSound(`${prefix}/block-sun-7.wav?${suffix}`, options);
  const [playBlockSun8, {
    sound: soundBlockSun8
  }] = useSound(`${prefix}/block-sun-8.wav?${suffix}`, options);
  const [playBlockSun9, {
    sound: soundBlockSun9
  }] = useSound(`${prefix}/block-sun-9.wav?${suffix}`, options);
  const [playBlockSun10, {
    sound: soundBlockSun10
  }] = useSound(`${prefix}/block-sun-10.wav?${suffix}`, options);
  const [playBlockSun11, {
    sound: soundBlockSun11
  }] = useSound(`${prefix}/block-sun-11.wav?${suffix}`, options);
  const [playBlockSun12, {
    sound: soundBlockSun12
  }] = useSound(`${prefix}/block-sun-12.wav?${suffix}`, options);
  const [playBlockSun13, {
    sound: soundBlockSun13
  }] = useSound(`${prefix}/block-sun-13.wav?${suffix}`, options);
  const [playBlockSun14, {
    sound: soundBlockSun14
  }] = useSound(`${prefix}/block-sun-14.wav?${suffix}`, options);
  const [playBlockSun15, {
    sound: soundBlockSun15
  }] = useSound(`${prefix}/block-sun-15.wav?${suffix}`, options);
  const [playBlockSun16, {
    sound: soundBlockSun16
  }] = useSound(`${prefix}/block-sun-16.wav?${suffix}`, options);
  const [playCellSun1, {
    sound: soundCellSun1
  }] = useSound(`${prefix}/cell-sun-1.wav?${suffix}`, options);
  const [playCellSun2, {
    sound: soundCellSun2
  }] = useSound(`${prefix}/cell-sun-2.wav?${suffix}`, options);
  const [playCellSun3, {
    sound: soundCellSun3
  }] = useSound(`${prefix}/cell-sun-3.wav?${suffix}`, options);
  const [playCellSun4, {
    sound: soundCellSun4
  }] = useSound(`${prefix}/cell-sun-4.wav?${suffix}`, options);
  const [playCellSun5, {
    sound: soundCellSun5
  }] = useSound(`${prefix}/cell-sun-5.wav?${suffix}`, options);
  const [playCellSun6, {
    sound: soundCellSun6
  }] = useSound(`${prefix}/cell-sun-6.wav?${suffix}`, options);
  const [playCellSun7, {
    sound: soundCellSun7
  }] = useSound(`${prefix}/cell-sun-7.wav?${suffix}`, options);
  const [playCellSun8, {
    sound: soundCellSun8
  }] = useSound(`${prefix}/cell-sun-8.wav?${suffix}`, options);
  const [playCellSun9, {
    sound: soundCellSun9
  }] = useSound(`${prefix}/cell-sun-9.wav?${suffix}`, options);
  const [playCellSun10, {
    sound: soundCellSun10
  }] = useSound(`${prefix}/cell-sun-10.wav?${suffix}`, options);
  const [playCellSun11, {
    sound: soundCellSun11
  }] = useSound(`${prefix}/cell-sun-11.wav?${suffix}`, options);
  const [playCellSun12, {
    sound: soundCellSun12
  }] = useSound(`${prefix}/cell-sun-12.wav?${suffix}`, options);
  const [playCellSun13, {
    sound: soundCellSun13
  }] = useSound(`${prefix}/cell-sun-13.wav?${suffix}`, options);
  const [playCellSun14, {
    sound: soundCellSun14
  }] = useSound(`${prefix}/cell-sun-14.wav?${suffix}`, options);
  const [playCellSun15, {
    sound: soundCellSun15
  }] = useSound(`${prefix}/cell-sun-15.wav?${suffix}`, options);
  const [playCellSun16, {
    sound: soundCellSun16
  }] = useSound(`${prefix}/cell-sun-16.wav?${suffix}`, options);
  const [playBlockDrop, {
    sound: soundBlockDrop
  }] = useSound(`${prefix}/block-drop.wav?${suffix}`, options);
  const [playBlockDropImmobile, {
    sound: soundBlockDropImmobile
  }] = useSound(`${prefix}/block-drop-immobile.wav?${suffix}`, options);
  const [playBlockLift, {
    sound: soundBlockLift
  }] = useSound(`${prefix}/block-lift.wav?${suffix}`, {
    ...options,
    interrupt: false
  });
  const [playCellMove, {
    sound: soundCellMove
  }] = useSound(`${prefix}/cell-move.wav?${suffix}`, {
    ...options,
    interrupt: false
  });
  const [playLevelAvailable, {
    sound: soundLevelAvailable
  }] = useSound(`${prefix}/level-available.wav?${suffix}`, {
    ...options,
    interrupt: false,
    volume: 0.5 * volume
  });
  const [playLevelDoneFull, {
    sound: soundLevelDoneFull
  }] = useSound(`${prefix}/level-done-full.wav?${suffix}`, {
    ...options,
    interrupt: false
  });
  const [playNActive, {
    sound: soundNActive
  }] = useSound(`${prefix}/n-active.wav?${suffix}`, options);
  const [playNDisactive, {
    sound: soundNDisactive
  }] = useSound(`${prefix}/n-disactive.wav?${suffix}`, options);
  const [playNPlusOne, {
    sound: soundNPlusOne
  }] = useSound(`${prefix}/n-plus-one.wav?${suffix}`, options);
  const [playNodeFire, {
    sound: soundNodeFire
  }] = useSound(`${prefix}/node-fire.wav?${suffix}`, {
    ...options,
    interrupt: false
  });
  const [playNodeWater, {
    sound: soundNodeWater
  }] = useSound(`${prefix}/node-water.wav?${suffix}`, {
    ...options,
    interrupt: false,
    volume: 0.5 * volume
  });
  const [playSinkSatisfied, {
    sound: soundSinkSatisfied
  }] = useSound(`${prefix}/sink-satisfied.wav?${suffix}`, options);
  const [playSinkSatisfiedEnd, {
    sound: soundSinkSatisfiedEnd
  }] = useSound(`${prefix}/sink-satisfied-end.wav?${suffix}`, options);
  const [playSinkSatisfiedEndFull, {
    sound: soundSinkSatisfiedEndFull
  }] = useSound(`${prefix}/sink-satisfied-end-full.wav?${suffix}`, options);
  const [playSinkSatisfiedWater, {
    sound: soundSinkSatisfiedWater
  }] = useSound(`${prefix}/sink-satisfied-water.wav?${suffix}`, {
    ...options,
    volume: 0.5 * volume
  });
  const [playWeakActive, {
    sound: soundWeakActive
  }] = useSound(`${prefix}/weak-active.wav?${suffix}`, {
    ...options,
    volume: 0.5 * volume
  });
  const [playWeakDisactive, {
    sound: soundWeakDisactive
  }] = useSound(`${prefix}/weak-disactive.wav?${suffix}`, options);
  const [levelBackgroundPlaying, setLevelBackgroundPlayingRaw] = useState(undefined as "default" | "fire" | "night" | undefined);
  const levelBackgroundVolumeValue = useSpring(0, {
    duration: levelBackgroundPlaying ? 2000 : 10000
  });
  const [levelBackgroundVolume, setLevelBackgroundVolume] = useState(() => levelBackgroundVolumeValue.get());
  const [playLevelBackgroundDefault, {
    sound: soundLevelBackgroundDefault,
    pause: pauseLevelBackgroundDefault
  }] = useSound(`${prefix}/level-background-default.wav?${suffix}`, {
    ...options,
    loop: true,
    volume: 0.2 * levelBackgroundVolume * volume
  });
  const [playLevelBackgroundFire, {
    sound: soundLevelBackgroundFire,
    pause: pauseLevelBackgroundFire
  }] = useSound(`${prefix}/level-background-fire.wav?${suffix}`, {
    ...options,
    loop: true,
    volume: 0.2 * levelBackgroundVolume * volume
  });
  const [playLevelBackgroundNight, {
    sound: soundLevelBackgroundNight,
    pause: pauseLevelBackgroundNight
  }] = useSound(`${prefix}/level-background-night.wav?${suffix}`, {
    ...options,
    loop: true,
    volume: 0.2 * levelBackgroundVolume * volume
  });
  const setLevelBackgroundPlaying = useCallback((levelBackgroundPlaying: "default" | "fire" | "night" | undefined) => {
    setLevelBackgroundPlayingRaw(levelBackgroundPlaying);
    levelBackgroundVolumeValue.set(!levelBackgroundPlaying ? 0 : 1);
    if (!levelBackgroundPlaying) {
      return;
    }
    ({
      default: () => playLevelBackgroundDefault(),
      fire: () => playLevelBackgroundFire(),
      night: () => playLevelBackgroundNight()
    })[levelBackgroundPlaying]();
  }, [levelBackgroundVolumeValue, playLevelBackgroundDefault, playLevelBackgroundFire, playLevelBackgroundNight]);
  useMotionValueEvent(levelBackgroundVolumeValue, "change", volume => {
    setLevelBackgroundVolume(volume);
    if (!volume) {
      pauseLevelBackgroundDefault();
      pauseLevelBackgroundFire();
      pauseLevelBackgroundNight();
    }
  });
  const visibilityState = useDocumentVisibility();
  const windowFocus = useWindowFocus();
  const [muted, setMuted] = useMuted();
  const shouldHaveVolume = !muted && visibilityState === "visible" && windowFocus;
  useEffect(() => {
    setVolume(!shouldHaveVolume ? 0 : 1);
    if (!shouldHaveVolume) {
      levelBackgroundVolumeValue.setCurrent(0);
      return;
    }
    levelBackgroundVolumeValue.set(1);
  }, [levelBackgroundVolumeValue, shouldHaveVolume]);
  const sounds = useMemo(() => ({
    muted,
    playBlockDrop,
    playBlockDropImmobile,
    playBlockLift,
    playCellMove,
    playLevelAvailable,
    playLevelDoneFull,
    playNActive,
    playNDisactive,
    playNPlusOne,
    playNodeFire,
    playNodeWater,
    playSinkSatisfied,
    playSinkSatisfiedEnd,
    playSinkSatisfiedEndFull,
    playSinkSatisfiedWater,
    playWeakActive,
    playWeakDisactive,
    setLevelBackgroundPlaying,
    setMuted,
    playBlockSun: [playBlockSun1, playBlockSun2, playBlockSun3, playBlockSun4, playBlockSun5, playBlockSun6, playBlockSun7, playBlockSun8, playBlockSun9, playBlockSun10, playBlockSun11, playBlockSun12, playBlockSun13, playBlockSun14, playBlockSun15, playBlockSun16] satisfies Play16,
    playCellSun: [playCellSun1, playCellSun2, playCellSun3, playCellSun4, playCellSun5, playCellSun6, playCellSun7, playCellSun8, playCellSun9, playCellSun10, playCellSun11, playCellSun12, playCellSun13, playCellSun14, playCellSun15, playCellSun16] satisfies Play16,
    loaded: {
      playBlockDrop: Boolean(soundBlockDrop),
      playBlockDropImmobile: Boolean(soundBlockDropImmobile),
      playBlockLift: Boolean(soundBlockLift),
      playCellMove: Boolean(soundCellMove),
      playLevelAvailable: Boolean(soundLevelAvailable),
      playLevelBackground: {
        default: Boolean(soundLevelBackgroundDefault),
        fire: Boolean(soundLevelBackgroundFire),
        night: Boolean(soundLevelBackgroundNight)
      },
      playLevelDoneFull: Boolean(soundLevelDoneFull),
      playNActive: Boolean(soundNActive),
      playNDisactive: Boolean(soundNDisactive),
      playNPlusOne: Boolean(soundNPlusOne),
      playNodeFire: Boolean(soundNodeFire),
      playNodeWater: Boolean(soundNodeWater),
      playSinkSatisfied: Boolean(soundSinkSatisfied),
      playSinkSatisfiedEnd: Boolean(soundSinkSatisfiedEnd),
      playSinkSatisfiedEndFull: Boolean(soundSinkSatisfiedEndFull),
      playSinkSatisfiedWater: Boolean(soundSinkSatisfiedWater),
      playWeakActive: Boolean(soundWeakActive),
      playWeakDisactive: Boolean(soundWeakDisactive),
      playBlockSun: [Boolean(soundBlockSun1), Boolean(soundBlockSun2), Boolean(soundBlockSun3), Boolean(soundBlockSun4), Boolean(soundBlockSun5), Boolean(soundBlockSun6), Boolean(soundBlockSun7), Boolean(soundBlockSun8), Boolean(soundBlockSun9), Boolean(soundBlockSun10), Boolean(soundBlockSun11), Boolean(soundBlockSun12), Boolean(soundBlockSun13), Boolean(soundBlockSun14), Boolean(soundBlockSun15), Boolean(soundBlockSun16)] satisfies Boolean16,
      playCellSun: [Boolean(soundCellSun1), Boolean(soundCellSun2), Boolean(soundCellSun3), Boolean(soundCellSun4), Boolean(soundCellSun5), Boolean(soundCellSun6), Boolean(soundCellSun7), Boolean(soundCellSun8), Boolean(soundCellSun9), Boolean(soundCellSun10), Boolean(soundCellSun11), Boolean(soundCellSun12), Boolean(soundCellSun13), Boolean(soundCellSun14), Boolean(soundCellSun15), Boolean(soundCellSun16)] satisfies Boolean16,
      all: Boolean(soundBlockDrop && soundBlockDropImmobile && soundBlockLift && soundBlockSun1 && soundBlockSun10 && soundBlockSun11 && soundBlockSun12 && soundBlockSun13 && soundBlockSun14 && soundBlockSun15 && soundBlockSun16 && soundBlockSun2 && soundBlockSun3 && soundBlockSun4 && soundBlockSun5 && soundBlockSun6 && soundBlockSun7 && soundBlockSun8 && soundBlockSun9 && soundCellMove && soundCellSun1 && soundCellSun10 && soundCellSun11 && soundCellSun12 && soundCellSun13 && soundCellSun14 && soundCellSun15 && soundCellSun16 && soundCellSun16 && soundCellSun2 && soundCellSun3 && soundCellSun4 && soundCellSun5 && soundCellSun6 && soundCellSun7 && soundCellSun8 && soundCellSun9 && soundLevelAvailable && soundLevelDoneFull && soundNActive && soundNDisactive && soundNodeFire && soundNodeWater && soundNPlusOne && soundSinkSatisfied && soundSinkSatisfiedEnd && soundSinkSatisfiedEndFull && soundSinkSatisfiedWater && soundWeakActive && soundWeakDisactive)
    }
  }), [muted, playBlockDrop, playBlockDropImmobile, playBlockLift, playBlockSun1, playBlockSun10, playBlockSun11, playBlockSun12, playBlockSun13, playBlockSun14, playBlockSun15, playBlockSun16, playBlockSun2, playBlockSun3, playBlockSun4, playBlockSun5, playBlockSun6, playBlockSun7, playBlockSun8, playBlockSun9, playCellMove, playCellSun1, playCellSun10, playCellSun11, playCellSun12, playCellSun13, playCellSun14, playCellSun15, playCellSun16, playCellSun2, playCellSun3, playCellSun4, playCellSun5, playCellSun6, playCellSun7, playCellSun8, playCellSun9, playLevelAvailable, playLevelDoneFull, playNActive, playNDisactive, playNodeFire, playNodeWater, playNPlusOne, playSinkSatisfied, playSinkSatisfiedEnd, playSinkSatisfiedEndFull, playSinkSatisfiedWater, playWeakActive, playWeakDisactive, setLevelBackgroundPlaying, setMuted, soundBlockDrop, soundBlockDropImmobile, soundBlockLift, soundBlockSun1, soundBlockSun10, soundBlockSun11, soundBlockSun12, soundBlockSun13, soundBlockSun14, soundBlockSun15, soundBlockSun16, soundBlockSun2, soundBlockSun3, soundBlockSun4, soundBlockSun5, soundBlockSun6, soundBlockSun7, soundBlockSun8, soundBlockSun9, soundCellMove, soundCellSun1, soundCellSun10, soundCellSun11, soundCellSun12, soundCellSun13, soundCellSun14, soundCellSun15, soundCellSun16, soundCellSun2, soundCellSun3, soundCellSun4, soundCellSun5, soundCellSun6, soundCellSun7, soundCellSun8, soundCellSun9, soundLevelAvailable, soundLevelBackgroundDefault, soundLevelBackgroundFire, soundLevelBackgroundNight, soundLevelDoneFull, soundNActive, soundNDisactive, soundNodeFire, soundNodeWater, soundNPlusOne, soundSinkSatisfied, soundSinkSatisfiedEnd, soundSinkSatisfiedEndFull, soundSinkSatisfiedWater, soundWeakActive, soundWeakDisactive]);
  return <Sounds.Provider value={sounds} data-sentry-element="unknown" data-sentry-component="LoadSounds" data-sentry-source-file="use-sound.tsx">{children}</Sounds.Provider>;
};