import { useEffect, useState } from "react";

export const useWindowFocus = () => {
  const [focus, setFocus] = useState(true);

  useEffect(() => {
    const callback = () => setFocus(document.hasFocus());

    callback();

    window.addEventListener("blur", callback);
    window.addEventListener("focus", callback);

    return () => {
      window.removeEventListener("blur", callback);
      window.removeEventListener("focus", callback);
    };
  }, []);

  return focus;
};
