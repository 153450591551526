import type { ComponentProps } from "react";
import { sizes } from "../sizes";
export const ButtonRow = ({
  children,
  className,
  style,
  ...props
}: ComponentProps<"div">) => <div className={`flex w-full items-center text-gray-500 mix-blend-difference focus-within:text-gray-400 hover:text-gray-400 ${className} `} style={{
  ...style,
  height: sizes.menu.rem * 16
}} {...props} data-sentry-component="ButtonRow" data-sentry-source-file="index.tsx">
    {children}
  </div>;