import { tailwindConfig } from "./tailwind-config";

const {
  theme: { width },
} = tailwindConfig;

export const sizes = {
  cell: {
    rem: Number(width["32"]!.replace(/rem$/, "")),
    className: "size-32",
  },
  block: {
    rem: Number(width["28"]!.replace(/rem$/, "")),
    className: "size-28",
  },
  betweenBlockAndCell: {
    rem: 7.5,
    className: "size-[7.5rem]",
  },
  distanceBetween: {
    rem:
      Number(width["32"]!.replace(/rem$/, "")) -
      Number(width["28"]!.replace(/rem$/, "")),
    className: "size-4",
  },
  blockContent: {
    rem: Number(width["24"]!.replace(/rem$/, "")),
    className: "size-24",
  },
  blockInnerContent: {
    rem: Number(width["20"]!.replace(/rem$/, "")),
    className: "size-20",
  },
  blockInnerInnerContent: {
    rem: Number(width["14"]!.replace(/rem$/, "")),
    className: "size-14",
  },
  menu: {
    rem: Number(width["14"]!.replace(/rem$/, "")),
    className: "size-14",
  },
  control: {
    rem: Number(width["10"]!.replace(/rem$/, "")),
    className: "size-10",
  },
} as const;
