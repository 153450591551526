import { motion } from "framer-motion";
import { mapValues } from "lodash/fp";
import Head from "next/head";
import { createContext, useContext, useEffect, useState } from "react";
import type { ComponentProps, Dispatch, SetStateAction } from "react";
import { tailwindConfig } from "../tailwind-config";
import { useMatchMedia } from "../use-match-media";
import { useWindowDimensions } from "../use-window-dimensions";
const {
  theme: {
    colors: {
      black,
      blue,
      brown,
      gray,
      orange,
      pink,
      purple,
      red,
      sky,
      stone,
      teal
    }
  }
} = tailwindConfig;

// TODO Add <meta name="apple-mobile-web-app-status-bar-style" content="black">
// https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html?ref=frontendchecklist
const backgrounds = {
  default: {
    light: {
      gradientFrom: sky[50],
      gradientTo: sky[200],
      themeColor: sky[100]
    },
    dark: {
      gradientFrom: sky[900],
      gradientTo: sky[950],
      themeColor: sky[900]
    }
  },
  inactive: {
    light: {
      gradientFrom: stone[50],
      gradientTo: stone[400],
      themeColor: stone[400]
    },
    dark: {
      gradientFrom: stone[700],
      gradientTo: stone[800],
      themeColor: stone[800]
    }
  },
  ground: {
    light: {
      gradientFrom: brown[50],
      gradientTo: brown[400],
      themeColor: brown[400]
    },
    dark: {
      gradientFrom: orange[900],
      gradientTo: brown[900],
      themeColor: brown[900]
    }
  },
  n: {
    light: {
      gradientFrom: sky[50],
      gradientTo: teal[200],
      themeColor: teal[200]
    },
    dark: {
      gradientFrom: sky[900],
      gradientTo: teal[900],
      themeColor: teal[900]
    }
  },
  weak: {
    light: {
      gradientFrom: pink[100],
      gradientTo: purple[300],
      themeColor: pink[200]
    },
    dark: {
      gradientFrom: pink[950],
      gradientTo: purple[950],
      themeColor: pink[950]
    }
  },
  disactive: {
    light: {
      gradientFrom: pink[100],
      gradientTo: red[400],
      themeColor: red[200]
    },
    dark: {
      gradientFrom: pink[900],
      gradientTo: red[950],
      themeColor: red[950]
    }
  },
  fire: {
    light: {
      gradientFrom: blue[400],
      gradientTo: orange[400],
      themeColor: orange[400]
    },
    dark: {
      gradientFrom: blue[950],
      gradientTo: orange[900],
      themeColor: orange[900]
    }
  },
  night: {
    light: {
      gradientFrom: gray[900],
      gradientTo: black,
      themeColor: gray[900]
    },
    dark: {
      gradientFrom: gray[900],
      gradientTo: black,
      themeColor: gray[900]
    }
  }
};
const defaultValue = {
  background: undefined as keyof typeof backgrounds | undefined,
  immediate: undefined as boolean | undefined
};
const defaultContextValue = [defaultValue, (() => {}) as Dispatch<SetStateAction<typeof defaultValue>>] satisfies [any, any];
const BackgroundContext = createContext(defaultContextValue);
export const useBackground = (background?: keyof typeof backgrounds, {
  immediate = false
} = {}) => {
  const [{
    background: currentBackground
  }, setBackground] = useContext(BackgroundContext);
  useEffect(() => {
    if (!background) {
      return () => {};
    }
    setBackground({
      background,
      immediate
    });
    return () => setBackground({
      background: undefined,
      immediate: false
    });
  }, [background, immediate, setBackground]);
  return currentBackground;
};
const BackgroundComponent = ({
  children,
  className,
  background: backgroundProp = "default",
  immediate: immediateProp = false,
  ...props
}: ComponentProps<typeof motion.div> & {
  background?: keyof typeof backgrounds;
  immediate?: boolean;
}) => {
  const [lastPrefersDark, setLastPrefersDark] = useState<boolean>();
  const prefersDark = useMatchMedia("(prefers-color-scheme: dark)");
  useEffect(() => setLastPrefersDark(prefersDark), [prefersDark]);
  const colorScheme = prefersDark ? "dark" : "light";
  const [{
    immediate = immediateProp,
    background = backgroundProp
  }] = useContext(BackgroundContext);
  const {
    height
  } = useWindowDimensions();
  const [themeColor, setThemeColor] = useState<string>(backgrounds.default[colorScheme].themeColor);
  return <>
      <motion.div className="hidden" initial={{
      backgroundColor: backgrounds.default[colorScheme].themeColor
    }} animate={{
      backgroundColor: (!background ? undefined : backgrounds[background])?.[colorScheme].themeColor ?? backgrounds.default[colorScheme].themeColor,
      transition: {
        duration: lastPrefersDark === undefined || immediate ? 0 : 3
      }
    }} onUpdate={({
      backgroundColor
    }) => setThemeColor(backgroundColor as string)} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        {["msapplication-TileColor", "theme-color", "msapplication-navbutton-color", "apple-mobile-web-app-status-bar-style"].map(name => <meta key={name} name={name} content={themeColor} />)}
      </Head>
      <motion.div {...props} className={`fixed flex h-screen w-screen touch-none select-none flex-col items-center justify-center ${className}`} style={{
      height,
      willChange: "background-image"
    }} initial="default" animate={background} variants={mapValues(({
      [colorScheme]: {
        gradientFrom = backgrounds.default[colorScheme].gradientFrom,
        gradientTo = backgrounds.default[colorScheme].gradientTo
      }
    }) => ({
      backgroundImage: `linear-gradient(to bottom right, ${gradientFrom}, ${gradientTo})`,
      transition: {
        duration: lastPrefersDark === undefined || immediate ? 0 : 3
      }
    }), backgrounds)} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {children}
      </motion.div>
    </>;
};
export const Background = (props: ComponentProps<typeof BackgroundComponent>) => {
  const state = useState(defaultContextValue[0]);
  return <BackgroundContext.Provider value={state} data-sentry-element="unknown" data-sentry-component="Background" data-sentry-source-file="index.tsx">
      <BackgroundComponent {...props} data-sentry-element="BackgroundComponent" data-sentry-source-file="index.tsx" />
    </BackgroundContext.Provider>;
};