import { sortBy } from "lodash/fp";
import { ObjectId } from "mongodb/lib/bson";
import hash from "object-hash";
import { z } from "zod";

import { queue } from "@sunblocks/utils";

import { getCellByPosition } from "./logic";
import { isWinLazy } from "./logic-lazy";
import type { Position } from "./position";

const zActive = z.union([
  z.literal("active"),
  z.literal("disactive"),
  z.literal("inactive"),
]);

export type Active = z.infer<typeof zActive>;

const zActiveBoolean = z
  .union([zActive, z.boolean()])
  .transform((active) =>
    typeof active !== "boolean" ? active : active ? "active" : "inactive"
  );

const zFill = z.union([
  z.literal("fire"),
  z.literal("sun"),
  z.literal("unfilled"),
  z.literal("water"),
]);

export type Fill = z.infer<typeof zFill>;

const zNodeProps = z.object({
  active: z.optional(zActiveBoolean),
  source: z.optional(
    z.union([
      z.literal("fire"),
      z.literal("sun"),
      z.literal("water"),
      z.boolean().transform((source) => (source ? "sun" : undefined)),
    ])
  ),
  sink: z.optional(
    z.union([
      z.literal("sun"),
      z.literal("unfilled"),
      z.literal("water"),
      z.boolean().transform((sink) => (sink ? "sun" : undefined)),
    ])
  ),
});

const zPosition = z.tuple([z.number().int(), z.number().int()]);

const zCellBase = zNodeProps.extend({
  position: zPosition,
});

const zCellModel = zCellBase
  .omit({
    position: true,
  })
  .extend({
    cell: z.literal(true),
  });

export type CellModel = z.infer<typeof zCellModel>;

const zCell = zCellBase
  .transform((cell) => ({
    ...cell,
    _key: `cell-${cell.position.join("/")}`,
  }))
  .transform(
    ({
      sink: sinkInitial,
      source: sourceInitial,
      sink = sourceInitial === "fire" ? "water" : undefined,
      source = sinkInitial === "water" ? "fire" : undefined,
      active = sink || source ? "active" : "inactive",
      ...cell
    }) => ({
      ...cell,
      active,
      sink,
      source,
    })
  );

export type CellData = z.input<typeof zCell>;
export type Cell = z.output<typeof zCell>;

const zBlockBase = zNodeProps.extend({
  cell: z.optional(z.literal(false)),
  initialPosition: zPosition,
  mobile: z.optional(z.boolean()),
  n: z.optional(z.number().int()),
  sunColor: z.optional(z.string()),
  weak: z.optional(z.boolean()),
  shape: z
    .array(zPosition)
    .min(1)
    .transform(
      sortBy(([y, x]) => `${y}`.padStart(3, "0") + `${x}`.padStart(3, "0"))
    )
    .superRefine((shape, ctx) => {
      const shapeSet = shape.reduce((set, [y, x], index) => {
        const positionString = `${y}/${x}`;

        if (set.has(positionString)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Cannot have duplicate shape positions.",
            path: [index],
          });
        }

        set.add(positionString);

        return set;
      }, new Set<string>());

      const remaining = queue((set, [y, x], { push }) => {
        const positionString = `${y}/${x}`;

        if (!set.has(positionString)) {
          return set;
        }

        set.delete(positionString);

        push([y - 1, x], [y + 1, x], [y, x - 1], [y, x + 1]);

        return set;
      }, shapeSet)([shape[0]!]);

      if (remaining.size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Shape positions must be adjacent to one another.",
        });
      }
    }),
});

const zBlockModel = zBlockBase
  .omit({
    initialPosition: true,
    shape: true,
  })
  .transform(({ ...block }) => ({
    ...block,
    cell: false as const,
  }));

export type BlockModel = z.infer<typeof zBlockModel>;

const zBlock = zBlockBase
  .transform((block) => {
    const yMin = Math.min(...block.shape.map(([y]) => y));
    const xMin = Math.min(...block.shape.map(([, x]) => x));

    return yMin === 0 && xMin === 0
      ? block
      : {
          ...block,
          initialPosition: [
            block.initialPosition[0] + yMin,
            block.initialPosition[1] + xMin,
          ] satisfies Position,
          shape: block.shape.map(
            ([y, x]) => [y - yMin, x - xMin] satisfies Position
          ),
        };
  })
  .transform((block) => ({
    ...block,
    _key: `block-${block.initialPosition.join("-")}-${block.shape
      .map((position) => position.join("/"))
      .join("//")}`,
  }))
  .transform(
    ({
      sink: sinkInitial,
      source: sourceInitial,
      sink = sourceInitial === "fire" ? "water" : undefined,
      source = sinkInitial === "water" ? "fire" : undefined,
      mobile = !sink && !source,
      ...block
    }) => ({
      ...block,
      mobile,
      sink,
      source,
    })
  );

export type BlockData = z.input<typeof zBlock>;
export type Block = z.output<typeof zBlock>;

export type Node = Block | Cell;

const zModel = z.object({
  active: z.optional(zActiveBoolean),
  fill: z.optional(zFill),
  nCount: z.optional(z.number().int()),
  model: z.optional(z.union([zBlockModel, zCellModel])),
  night: z.optional(z.boolean()),
});

type Model = z.infer<typeof zModel>;

const mergeModels = (...models: (Model | undefined)[]): Model =>
  models.reduce(
    (acc: Model, model) => ({
      ...acc,
      ...model,
      model: {
        ...acc.model,
        ...model?.model,
      } as Model["model"],
    }),
    {} as Model
  );

const zModels = z.object({
  all: z.optional(zModel),
  available: z.optional(zModel),
  locked: z.optional(zModel),
  won: z.optional(zModel),
  wonBest: z.optional(zModel),
});

type Models = z.infer<typeof zModels>;

const defaultModels: Models = {
  locked: { active: "inactive" },
  won: { fill: "sun" },
  wonBest: { fill: "sun", model: { cell: false, mobile: true, sink: "sun" } },
};

export const resolveModels = (
  label: keyof Models,
  ...modelses: (Models | undefined)[]
) =>
  modelses.reduce(
    (acc, models) => mergeModels(acc, models?.all, models?.[label]),
    mergeModels(defaultModels.all, defaultModels[label])
  );

const zBackground = z.union([
  z.literal("default"),
  z.literal("disactive"),
  z.literal("fire"),
  z.literal("ground"),
  z.literal("inactive"),
  z.literal("n"),
  z.literal("night"),
  z.literal("weak"),
]);

type Condition =
  | {
      conditions: Condition[];
      type: "and";
    }
  | {
      conditions: Condition[];
      type: "or";
    }
  | {
      count?: number | undefined;
      levelIds: ObjectId[];
      type: "levels";
    };

const zCondition: z.ZodType<Condition> = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("and"),
    conditions: z.array(z.lazy(() => zCondition)).min(2),
  }),
  z.object({
    type: z.literal("or"),
    conditions: z.array(z.lazy(() => zCondition)).min(2),
  }),
  z.object({
    type: z.literal("levels"),
    count: z.optional(z.number().int()),
    levelIds: z.array(z.instanceof(ObjectId)).min(1),
  }),
]);

export const zLevelData = z
  .object({
    background: z.optional(zBackground),
    condition: z.optional(zCondition),
    gridHeight: z.optional(z.number().int()),
    gridWidth: z.optional(z.number().int()),
    models: z.optional(zModels),
    tutorial: z.optional(z.boolean()).default(false),
    blocks: z
      .array(zBlock)
      .min(1)
      .transform((blocks) => {
        const maxShapesLength = Math.max(
          ...blocks.map(({ shape }) => shape.length)
        );

        return sortBy(
          ({ shape, initialPosition: [y, x] }) =>
            `${y}`.padStart(3, "0") +
            `${x}`.padStart(3, "0") +
            Array.from({ length: maxShapesLength }).map((_, index) =>
              !shape[index]
                ? "aaaaaa"
                : `${shape[index]?.[0]}`.padStart(3, "0") +
                  `${shape[index]?.[1]}`.padStart(3, "0")
            ),
          blocks
        );
      }),
    cells: z
      .array(zCell)
      .min(1)
      .transform(
        sortBy(
          ({ position: [y, x] }) =>
            `${y}`.padStart(3, "0") + `${x}`.padStart(3, "0")
        )
      ),
  })
  .transform(
    ({
      blocks,
      cells,
      gridHeight = Math.max(
        0,
        ...cells.map(({ position: [y] }) => y),
        ...blocks.flatMap(({ shape, initialPosition: [y] }) =>
          shape.map((position) => y + position[0])
        )
      ) + 1,
      gridWidth = Math.max(
        0,
        ...cells.map(({ position: [, x] }) => x),
        ...blocks.flatMap(({ shape, initialPosition: [, x] }) =>
          shape.map((position) => x + position[1])
        )
      ) + 1,
      ...level
    }) => ({
      ...level,
      blocks,
      cells,
      gridHeight,
      gridWidth,
    })
  )
  .transform(({ blocks, cells, gridHeight, gridWidth, ...level }) => {
    const yMin = Math.min(
      0,
      ...blocks.map(({ initialPosition: [y] }) => y),
      ...cells.map(({ position: [y] }) => y)
    );
    const xMin = Math.min(
      0,
      ...blocks.map(({ initialPosition: [, x] }) => x),
      ...cells.map(({ position: [, x] }) => x)
    );

    return {
      ...level,
      ...(yMin === 0 && xMin === 0
        ? {
            blocks,
            cells,
            gridHeight,
            gridWidth,
          }
        : {
            gridHeight: gridHeight - yMin,
            gridWidth: gridWidth - xMin,
            blocks: blocks.map(({ initialPosition: [y, x], ...block }) => ({
              ...block,
              initialPosition: [y - yMin, x - xMin] satisfies Position,
            })),
            cells: cells.map(({ position: [y, x], ...cell }) => ({
              ...cell,
              position: [y - yMin, x - xMin] satisfies Position,
            })),
          }),
    };
  })
  .transform(({ blocks, cells, ...level }) => ({
    ...level,
    blocks,
    cells,
    _rev: hash({ blocks, cells }, { excludeKeys: (key) => key === "_key" }),
  }))
  .superRefine(({ blocks, cells }, ctx) => {
    cells.reduce((occupied, { position }, index) => {
      const key = position.join(",");

      if (occupied.has(key)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Cannot have cells in the same position.",
          path: ["cells"],
          params: {
            position,
            cellIndexes: [occupied.get(key)!, index],
            cells: [cells[occupied.get(key)!], cells[index]],
          },
        });
      }

      occupied.set(key, index);

      return occupied;
    }, new Map<string, number>());

    const cellByPosition = getCellByPosition({ cells });

    blocks.reduce((occupied, block, index) => {
      const { initialPosition, shape } = block;

      const shapeTruePositions = shape.map(
        ([y, x]) =>
          [initialPosition[0] + y, initialPosition[1] + x] satisfies Position
      );

      const positionsOffGrid = shapeTruePositions.filter(
        ([y, x]) => !cellByPosition[y]?.[x]
      );

      if (positionsOffGrid.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Blocks must be fully on the grid",
          path: ["blocks", index],
          params: {
            block,
            positionsOffGrid,
          },
        });
      }

      const indexPositions = shapeTruePositions
        .map(([y, x]) => occupied.get([y, x].join(",")))
        .filter(
          (
            indexPosition
          ): indexPosition is { index: number; position: Position } =>
            indexPosition !== undefined
        );

      if (indexPositions.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Blocks cannot overlap.",
          path: ["blocks"],
          params: {
            positionsOverlapping: indexPositions.map(
              ({ position }) => position
            ),
            blockIndexes: [...indexPositions.map(({ index }) => index), index],
            blocks: [
              ...indexPositions.map(({ index }) => blocks[index]!),
              block,
            ],
          },
        });
      }

      return shapeTruePositions.reduce((occupied, position) => {
        occupied.set(position.join(","), { index, position });
        return occupied;
      }, occupied);
    }, new Map<string, { index: number; position: Position }>());

    if (isWinLazy({ blocks, cells }, { cellByPosition })) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Cannot be an immediate win.",
      });
    }
  });

export type LevelData = z.input<typeof zLevelData>;

const zLevel = z.intersection(
  zLevelData,
  z.object({ _id: z.instanceof(ObjectId) })
);

export type Level = z.output<typeof zLevel>;

export const isConditionMet = (
  condition: Condition | undefined,
  personalBestScores: { [levelId: string]: number | undefined }
): boolean =>
  !condition ||
  (condition.type === "levels" &&
    condition.levelIds.filter((levelId) => personalBestScores[`${levelId}`])
      .length >= (condition.count ?? condition.levelIds.length)) ||
  (condition.type === "and" &&
    condition.conditions.every((condition) =>
      isConditionMet(condition, personalBestScores)
    )) ||
  (condition.type === "or" &&
    condition.conditions.some((condition) =>
      isConditionMet(condition, personalBestScores)
    ));

export const zArea = z.object({
  _id: z.instanceof(ObjectId),
  background: z.optional(zBackground),
  levels: z.array(zLevel).min(1),
  models: z.optional(zModels),
});

export type AreaData = z.input<typeof zArea>;
export type Area = z.output<typeof zArea>;

export const zMove = z.object({
  _key: z.string(),
  position: zPosition,
});

export type Move = z.infer<typeof zMove>;
