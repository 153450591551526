import DarkModeRounded from "@mui/icons-material/DarkModeRounded";
import StarRounded from "@mui/icons-material/StarRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";
import { fired } from "../Fire";
import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { watered } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      gray,
      yellow
    }
  }
} = tailwindConfig;

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const moonScale = 1.100004;
export const Moon = ({
  className,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  immediate?: boolean;
}) => <motion.div className={`pointer-events-none relative ${sizes.blockContent.className} ${className}`} data-sentry-element="unknown" data-sentry-component="Moon" data-sentry-source-file="index.tsx">
    {!immediate && <Sprinkle className="absolute top-0" variantName="unfilled" />}
    <MotionDiv {...props} className="absolute top-0" variants={{
    inactive: {
      opacity: 0.5,
      scale: 1,
      color: gray[100]
    },
    disactive: {
      opacity: 0.75,
      scale: 1,
      color: gray[100]
    },
    sun: {
      opacity: 0.75,
      scale: 1,
      color: gray[100]
    },
    fire: {
      opacity: 1,
      scale: 1,
      color: fired
    },
    water: {
      opacity: 1,
      scale: 1,
      color: watered
    },
    unfilled: {
      opacity: 1,
      scale: [1, 2, 1.8, 1],
      color: yellow[200],
      transition: immediate ? {
        delay: 0,
        duration: 0
      } : {
        delay: 0.15,
        duration: 0.4,
        bounce: true
      }
    }
  }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
      <DarkModeRounded style={{
      height: `${sizes.blockContent.rem}rem`,
      width: `${sizes.blockContent.rem}rem`
    }} data-sentry-element="DarkModeRounded" data-sentry-source-file="index.tsx" />
    </MotionDiv>
    <motion.div variants={{
    start: {
      opacity: 0
    },
    inactive: {
      opacity: 0
    },
    disactive: {
      opacity: 0
    },
    sun: {
      opacity: 0
    },
    fire: {
      opacity: 0
    },
    water: {
      opacity: 0
    },
    animating: {
      opacity: 1,
      transition: {
        delay: 0.75
      }
    }
  }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
      <motion.div className="absolute right-5 top-0 text-yellow-300" variants={{
      start: {
        opacity: 0,
        scale: 1,
        rotate: "-36deg"
      },
      inactive: {
        opacity: 0,
        scale: 1
      },
      disactive: {
        opacity: 0,
        scale: 1
      },
      sun: {
        opacity: 0,
        scale: 1
      },
      fire: {
        opacity: 0,
        scale: 1
      },
      water: {
        opacity: 0,
        scale: 1
      },
      animating: {
        scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        opacity: [1, 0.5],
        transition: {
          delay: 1.75,
          duration: 5,
          repeat: Infinity,
          ease: "linear"
        }
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <StarRounded className="-translate-y-1/4" style={{
        height: `${sizes.blockContent.rem / 7}rem`,
        width: `${sizes.blockContent.rem / 7}rem`
      }} data-sentry-element="StarRounded" data-sentry-source-file="index.tsx" />
      </motion.div>
      <motion.div className="absolute right-8 top-5 text-yellow-300" variants={{
      start: {
        opacity: 0,
        scale: 1,
        rotate: "20deg"
      },
      inactive: {
        opacity: 0,
        scale: 1
      },
      disactive: {
        opacity: 0,
        scale: 1
      },
      sun: {
        opacity: 0,
        scale: 1
      },
      fire: {
        opacity: 0,
        scale: 1
      },
      water: {
        opacity: 0,
        scale: 1
      },
      animating: {
        scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        opacity: [1, 0.5],
        transition: {
          delay: 2.25,
          duration: 5,
          repeat: Infinity,
          ease: "linear"
        }
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <StarRounded className="-translate-y-2/3" style={{
        height: `${sizes.blockContent.rem / 8}rem`,
        width: `${sizes.blockContent.rem / 8}rem`
      }} data-sentry-element="StarRounded" data-sentry-source-file="index.tsx" />
      </motion.div>
      <motion.div className="absolute right-2 top-6 text-yellow-300" variants={{
      start: {
        opacity: 0,
        scale: 1,
        rotate: "-12deg"
      },
      inactive: {
        opacity: 0,
        scale: 1
      },
      disactive: {
        opacity: 0,
        scale: 1
      },
      sun: {
        opacity: 0,
        scale: 1
      },
      fire: {
        opacity: 0,
        scale: 1
      },
      water: {
        opacity: 0,
        scale: 1
      },
      animating: {
        scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        opacity: [1, 0.5],
        transition: {
          delay: 2.75,
          duration: 5,
          repeat: Infinity,
          ease: "linear"
        }
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <StarRounded className="-translate-y-1/3" style={{
        height: `${sizes.blockContent.rem / 6}rem`,
        width: `${sizes.blockContent.rem / 6}rem`
      }} data-sentry-element="StarRounded" data-sentry-source-file="index.tsx" />
      </motion.div>
    </motion.div>
  </motion.div>;