import { motion } from "framer-motion";
import { useMemo } from "react";
import type { ComponentProps } from "react";
import type { Active, Cell, CellModel as CellModelType, Fill, Position, getCellByPosition } from "@sunblocks/game";
import { useBackground } from "../Background";
import { fireScale } from "../Fire";
import { Flower } from "../Flower";
import { MotionDiv } from "../Motion";
import { Sun, sunScale } from "../Sun";
import { waterScale } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
import { useMatchMedia } from "../use-match-media";
import { useSounds } from "../use-sound";
const {
  theme: {
    colors: {
      brown,
      cyan,
      green,
      red,
      orange,
      yellow
    }
  }
} = tailwindConfig;
export const CellModel = ({
  cell,
  className,
  // TODO Better name
  getSunIndex,
  highlighted,
  onAnimationComplete,
  shadow,
  animating = true,
  cellByPosition = {},
  variants = {},
  fill = cell.source ?? "unfilled",
  immediate = false,
  pickPlaySinkSatisfied = ({
    playSinkSatisfied
  }) => playSinkSatisfied,
  ready = true,
  sounds = true,
  active: activeProp = cell.active,
  cell: {
    sink,
    source,
    active: activeInitial = "inactive"
  },
  ...props
}: Omit<ComponentProps<typeof MotionDiv>, "animate" | "initial"> & {
  active?: CellModelType["active"];
  animate?: MaybeArray<string>;
  animating?: boolean;
  cell: Cell | Omit<CellModelType, "cell">;
  cellByPosition?: ReturnType<typeof getCellByPosition>;
  fill?: Fill;
  getSunIndex?: () => number;
  highlighted?: boolean;
  immediate?: boolean;
  initial?: MaybeArray<string>;
  neighbors?: {
    down?: Pick<CellModelType, "active">;
    left?: Pick<CellModelType, "active">;
    right?: Pick<CellModelType, "active">;
    up?: Pick<CellModelType, "active">;
  };
  pickPlaySinkSatisfied?: (sounds: {
    playSinkSatisfied: () => void;
    playSinkSatisfiedEnd: () => void;
    playSinkSatisfiedEndFull: () => void;
  }) => () => void;
  ready?: boolean;
  shadow?: boolean;
  sounds?: boolean;
}) => {
  const {
    active = activeInitial
  } = useMemo(() => ({
    ...cell,
    active: activeProp
  }), [activeProp, cell]);
  const {
    playCellMove,
    playCellSun,
    playNodeFire,
    playNodeWater,
    playSinkSatisfied,
    playSinkSatisfiedEnd,
    playSinkSatisfiedEndFull
  } = useSounds(sounds);
  const animate = useMemo(() => [shadow ? "shadow" : highlighted ? "highlighted" : "empty", active !== "disactive" && active, active === "active" && fill, active === "active" && (fill === sink || fill === source) && animating && "animating", active === "disactive" && "disactive"].filter(Boolean), [active, animating, fill, highlighted, shadow, sink, source]);
  const initial = useMemo(() => immediate ? animate : ["empty", activeInitial !== "disactive" && activeInitial, activeInitial === "active" && (source ?? "unfilled"), activeInitial === "disactive" && "disactive"].filter(Boolean), [activeInitial, animate, immediate, source]);
  const background = useBackground();
  const colorScheme = useMatchMedia("(prefers-color-scheme: dark)") ? "dark" : "light";
  return <MotionDiv {...props} className={`size-fit ${className}`} variants={{
    ...variants,
    hidden: {
      opacity: 0,
      scale: 0,
      ...variants?.hidden
    },
    visible: {
      opacity: 1,
      scale: 1,
      ...variants?.visible
    }
  }} onAnimationStartDelayed={{
    visible: () => playCellMove(),
    hidden: () => playCellMove()
  }} data-sentry-element="MotionDiv" data-sentry-component="CellModel" data-sentry-source-file="index.tsx">
      <MotionDiv className={`flex items-center justify-center rounded-md ${background === "night" ? "bg-neutral-800" : colorScheme === "dark" ? "bg-zinc-600" : "bg-slate-200"} ${sizes.cell.className}`} initial={immediate && ready ? animate : initial} animate={!ready ? [] : animate} onAnimationComplete={onAnimationComplete} onAnimationStart={!ready ? undefined : fill === sink || fill === source ? {} : {
      sun: () => playCellSun[Math.max(0, Math.min(15, Math.round(getSunIndex?.() ?? 0)))]?.(),
      fire: () => playNodeFire(),
      water: () => playNodeWater()
    }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
        <motion.div className={`pointer-events-none absolute z-20 rounded-xl ${background === "night" ? "bg-neutral-400" : colorScheme === "dark" ? "bg-zinc-400" : "bg-slate-400"} ${sizes.block.className}`} variants={{
        empty: {
          opacity: 0
        },
        highlighted: {
          opacity: 0.25
        },
        shadow: {
          opacity: 0.75
        }
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
        {activeInitial !== "inactive" && <>
            <div className="absolute">
              <motion.div className={`absolute z-10 ${sizes.betweenBlockAndCell.className}`} style={{
            translateY: "-50%",
            translateX: "-50%"
          }} variants={{
            disactive: {
              backgroundColor: red[500],
              scale: 1
            },
            unfilled: {
              backgroundColor: background === "night" || colorScheme === "dark" ? brown[500] : brown[700],
              scale: 1
            },
            sun: {
              backgroundColor: yellow[600],
              scale: immediate || source === "sun" ? 1 : [1, sunScale, 1],
              transition: {
                duration: 0.35
              }
            },
            fire: {
              backgroundColor: orange[700],
              scale: immediate || source === "fire" ? 1 : [1, fireScale, 1],
              transition: {
                duration: 0.4
              }
            },
            water: {
              backgroundColor: cyan[800],
              scale: immediate || source === "water" ? 1 : [1, waterScale, 1],
              transition: {
                duration: 0.6
              }
            }
          }} />
              <div className={`pointer-events-none absolute z-[80] ${background === "night" || colorScheme === "dark" ? "opacity-50" : "opacity-35"}`}>
                {"position" in cell && ([[0, 0], [-1, 0], [1, 0], [0, -1], [0, 1]] satisfies Position[]).filter(([y, x]) => y === 0 && x === 0 || (["active", "disactive"] as (Active | undefined)[]).includes(cellByPosition[cell.position[0] + y]?.[cell.position[1] + x]?.active)).map(([y, x]) => <motion.div key={`${y}/${x}`} className={`absolute ${y !== 0 || x !== 0 ? "" : "rounded-3xl"} ${sizes.blockInnerContent.className}`} style={{
              translateY: `${y * 25 - 50}%`,
              translateX: `${x * 25 - 50}%`
            }} variants={{
              disactive: {
                backgroundColor: red[500]
              },
              unfilled: {
                backgroundColor: background === "night" || colorScheme === "dark" ? brown[500] : brown[700]
              },
              sun: {
                backgroundColor: yellow[600],
                transition: {
                  duration: 0.35
                }
              },
              fire: {
                backgroundColor: orange[700],
                transition: {
                  duration: 0.4
                }
              },
              water: {
                backgroundColor: cyan[800],
                transition: {
                  duration: 0.6
                }
              }
            }} />)}
              </div>
            </div>
            <motion.div className="absolute">
              {source === "sun" && <Sun className="absolute z-[80]" variants={{
            inactive: {
              color: red[900]
            },
            disactive: {
              color: red[900]
            },
            unfilled: {
              color: red[900]
            },
            sun: {
              color: red[900]
            }
          }} style={{
            translateY: "-50%",
            translateX: "-50%"
          }} />}
              {sink === "sun" && <Flower className="z-[80]" immediate={immediate} variantsPetals={{
            inactive: {
              color: red[100]
            },
            disactive: {
              color: red[100]
            },
            unfilled: {
              color: red[100]
            },
            sun: {
              color: red[900]
            }
          }} variantsLeaves={{
            inactive: {
              color: red[100]
            },
            disactive: {
              color: red[100]
            },
            unfilled: {
              color: red[100]
            },
            sun: {
              color: green[900]
            }
          }} onAnimationStart={!ready ? undefined : {
            sun: () => pickPlaySinkSatisfied({
              playSinkSatisfied,
              playSinkSatisfiedEnd,
              playSinkSatisfiedEndFull
            })()
          }} />}
            </motion.div>
          </>}
      </MotionDiv>
    </MotionDiv>;
};