import WhatshotRounded from "@mui/icons-material/WhatshotRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";
import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { watered, wateredInner } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      black,
      gray,
      red,
      yellow
    }
  }
} = tailwindConfig;
export const fired = red[600];
const firedInner = yellow[400];

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const fireScale = 1.100002;
export const Fire = ({
  className,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  immediate?: boolean;
}) => <motion.div className={`pointer-events-none relative ${sizes.blockContent.className} ${className}`} data-sentry-element="unknown" data-sentry-component="Fire" data-sentry-source-file="index.tsx">
    {!immediate && <Sprinkle className="absolute top-0" variantName="water" />}
    <MotionDiv {...props} className={`absolute top-0 ${sizes.blockContent.className}`} variants={{
    // TODO We need some fire animations
    inactive: {
      opacity: 0.5,
      color: gray[100],
      scale: 1
    },
    disactive: {
      opacity: 1,
      color: fired,
      scale: 1
    },
    unfilled: {
      opacity: 1,
      color: fired,
      scale: 1
    },
    sun: {
      opacity: 1,
      color: black,
      scale: 1
    },
    fire: {
      opacity: 1,
      color: fired,
      scale: 1
    },
    water: {
      opacity: 1,
      color: watered,
      scale: [1, 2, 1.8, 1],
      transition: immediate ? {
        delay: 0,
        duration: 0
      } : {
        delay: 0.15,
        duration: 0.4,
        bounce: true
      }
    }
  }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
      <motion.div className={`absolute translate-x-1 translate-y-3 scale-50 rounded-full ${sizes.blockContent.className}`} variants={{
      // TODO We need some fire/water animations
      inactive: {
        opacity: 0,
        backgroundColor: gray[100]
      },
      disactive: {
        opacity: 1,
        backgroundColor: firedInner
      },
      unfilled: {
        opacity: 1,
        backgroundColor: firedInner
      },
      sun: {
        opacity: 1,
        backgroundColor: black
      },
      fire: {
        opacity: 1,
        backgroundColor: firedInner
      },
      water: {
        opacity: 1,
        backgroundColor: wateredInner
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      <WhatshotRounded className="relative" style={{
      height: `${sizes.blockContent.rem}rem`,
      width: `${sizes.blockContent.rem}rem`
    }} data-sentry-element="WhatshotRounded" data-sentry-source-file="index.tsx" />
    </MotionDiv>
  </motion.div>;