import type { Config } from "tailwindcss";

import { baseTailwindConfig } from "@sunblocks/tailwind";

// TODO Consolidate tailwind/postcss into it's own package? idk
const config = {
  ...baseTailwindConfig,
  content: ["../*/src/**/*.{js,ts,jsx,tsx,mdx}"],
} satisfies Config;

export default config;
