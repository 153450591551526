import WaterDropRounded from "@mui/icons-material/WaterDropRounded";
import type { ComponentProps } from "react";
import { MotionDiv } from "../Motion";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      black,
      blue,
      cyan
    }
  }
} = tailwindConfig;
export const watered = blue[900];
export const wateredInner = cyan[400];

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const waterScale = 1.100003;
export const Water = ({
  className,
  variants,
  ...props
}: ComponentProps<typeof MotionDiv>) => <MotionDiv {...props} className={`pointer-events-none ${sizes.blockContent.className} ${className}`} variants={{
  inactive: {
    color: wateredInner
  },
  disactive: {
    color: wateredInner
  },
  unfilled: {
    color: wateredInner
  },
  sun: {
    color: black
  },
  fire: {
    color: black
  },
  water: {
    color: wateredInner
  },
  ...variants
}} data-sentry-element="MotionDiv" data-sentry-component="Water" data-sentry-source-file="index.tsx">
    <WaterDropRounded style={{
    height: `${sizes.blockContent.rem}rem`,
    width: `${sizes.blockContent.rem}rem`
  }} data-sentry-element="WaterDropRounded" data-sentry-source-file="index.tsx" />
  </MotionDiv>;