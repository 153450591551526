import hash from "object-hash";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import type { ZodType, z } from "zod";
import { SuperJSON } from "src/superjson";
export const createStorage = <T,>(getStorage: () => Storage, key: string, schema: ZodType<T>) => {
  const StorageContext = createContext<ReturnType<typeof useState<z.infer<typeof schema>>>>([undefined, () => {
    throw new Error("NEEDS TO BE IN A PROVIDER");
  }]);
  const useStorage = () => useContext(StorageContext);
  const useScopedStorage = <T,>(get: (stored: z.infer<typeof schema> | undefined) => T, set: (stored: z.infer<typeof schema> | undefined, value: T) => z.infer<typeof schema>): [T, Dispatch<SetStateAction<T>>] => {
    const [stored, setStored] = useStorage();
    const scopedValue = useMemo(() => get(stored), [get, stored]);
    return [scopedValue, useCallback(action => setStored(stored => {
      const actionFn = action instanceof Function ? action : () => action;
      const newValue = actionFn(get(stored));
      return set(stored, newValue);
    }), [get, set, setStored])];
  };
  const StorageProvider = ({
    children
  }: {
    children: ReactNode;
  }) => {
    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState<z.infer<typeof schema>>();
    useEffect(() => {
      const value = getStorage().getItem(key);
      setValue(value === null ? undefined : schema.parse(SuperJSON.parse(value)));
      setLoaded(true);
    }, []);
    useEffect(() => {
      const callback = ({
        storageArea,
        key: eventKey,
        newValue: newValueString
      }: StorageEvent) => {
        if (storageArea !== getStorage() || eventKey !== key) {
          return;
        }
        setValue(value => {
          if (newValueString === null) {
            return undefined;
          }
          const newValue = schema.parse(SuperJSON.parse(newValueString));
          return newValue === undefined ? undefined : value !== undefined && hash(newValue) === hash(value) ? value : newValue;
        });
      };
      window.addEventListener("storage", callback);
      return () => window.removeEventListener("storage", callback);
    }, []);
    const setStored: typeof setValue = useCallback(action => setValue(value => {
      const actionFn = action instanceof Function ? action : () => action;
      const newValue = actionFn(value);
      if (newValue === value) {
        return value;
      }
      if (newValue === undefined) {
        getStorage().removeItem(key);
        return newValue;
      }
      if (value !== undefined && hash(newValue) === hash(value)) {
        return value;
      }
      getStorage().setItem(key, SuperJSON.stringify(newValue));
      return newValue;
    }), []);
    return <StorageContext.Provider value={useMemo(() => [value, setStored], [setStored, value])} data-sentry-element="unknown" data-sentry-component="StorageProvider" data-sentry-source-file="use-storage.tsx">
        {loaded && children}
      </StorageContext.Provider>;
  };
  return {
    StorageProvider,
    useScopedStorage,
    useStorage
  };
};