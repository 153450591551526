import StarRounded from "@mui/icons-material/StarRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      orange
    }
  }
} = tailwindConfig;
const center = sizes.blockContent.rem / 2 - 1;
export const Sprinkle = ({
  className,
  variantName,
  ...props
}: ComponentProps<typeof motion.div> & {
  variantName: string;
}) => <motion.div {...props} className={`pointer-events-none relative ${sizes.blockContent.className} ${className}`} data-sentry-element="unknown" data-sentry-component="Sprinkle" data-sentry-source-file="index.tsx">
    {Array.from({
    length: 5
  }).map((foo, index) => index / 2.5 * Math.PI).map(radians => <motion.div key={radians} className="absolute" style={{
    opacity: 0,
    scale: 1,
    color: orange[600],
    top: `${center}rem`,
    left: `${center}rem`
  }} variants={{
    [variantName]: {
      opacity: [0, 1, 1, 0.75, 0],
      scale: [1, 1.2, 1, 1, 1],
      color: [orange[600], orange[400], orange[400], orange[400], orange[600]],
      top: [`${center}rem`, `${center - 4 * Math.cos(radians)}rem`, `${center - 4 * Math.cos(radians)}rem`, `${center - 4 * Math.cos(radians) + 0.5}rem`, `${center - 4 * Math.cos(radians) + 0.5}rem`],
      left: [`${center}rem`, `${center - 4 * Math.sin(radians)}rem`, `${center - 4 * Math.sin(radians)}rem`, `${center - 4 * Math.sin(radians)}rem`, `${center - 4 * Math.sin(radians)}rem`],
      transition: {
        duration: 0.75,
        delay: 0.3
      }
    }
  }}>
          <StarRounded style={{
      height: "2rem",
      width: "2rem"
    }} />
        </motion.div>)}
  </motion.div>;