import { useEffect, useState } from "react";

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const callback = () =>
      setWindowDimensions({
        height: global.window.innerHeight,
        width: global.window.innerWidth,
      });

    callback();
    global.window.addEventListener("orientationchange", callback);
    global.window.addEventListener("resize", callback);
    return () => {
      global.window.removeEventListener("orientationchange", callback);
      global.window.removeEventListener("resize", callback);
    };
  }, []);

  return windowDimensions;
};
