import type { ComponentProps } from "react";
export const Button = ({
  children,
  className,
  type = "button",
  "title": titleInitial,
  "aria-label": ariaLabelInitial,
  title = titleInitial ?? ariaLabelInitial,
  "aria-label": ariaLabel = ariaLabelInitial ?? titleInitial,
  ...props
}: ComponentProps<"button">) => <button
// eslint-disable-next-line react/button-has-type -- EXPECTED
type={type} aria-label={ariaLabel} title={title} className={`p-1 outline-blue-500 transition-colors hover:scale-110 hover:text-gray-50 focus:scale-110 focus:text-gray-50 disabled:text-gray-800 disabled:hover:scale-100 disabled:focus:scale-100 ${className}`} {...props} data-sentry-component="Button" data-sentry-source-file="index.tsx">
    {children}
  </button>;