import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";

import type { AppRouter } from "@sunblocks/trpc";

import { SuperJSON } from "src/superjson";
import { baseUrl } from "src/vercel/env";

export const trpc = createTRPCNext<AppRouter>({
  config: () => ({
    abortOnUnmount: true,
    transformer: SuperJSON,
    queryClientConfig:
      process.env.NODE_ENV !== "development"
        ? undefined
        : {
            defaultOptions: {
              queries: {
                networkMode: "always",
              },
              mutations: {
                networkMode: "always",
              },
            },
          },
    links: [
      loggerLink({
        enabled: (opts) =>
          (process.env.NODE_ENV === "development" &&
            typeof window !== "undefined") ||
          (opts.direction === "down" && opts.result instanceof Error),
      }),
      httpBatchLink({
        url: `${baseUrl}/api/trpc`,
      }),
    ],
  }),
});
