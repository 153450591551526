import { ObjectId } from "mongodb/lib/bson";
import SuperJSON from "superjson";

// TODO Consolidate superjson between packages

SuperJSON.registerCustom<ObjectId, string>(
  {
    isApplicable: (v): v is ObjectId => v instanceof ObjectId,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new ObjectId(v),
  },
  "mongodb.ObjectId"
);

export { default as SuperJSON } from "superjson";
