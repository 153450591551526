import LocalFloristRounded from "@mui/icons-material/LocalFloristRounded";
import { motion } from "framer-motion";
import type { Variants } from "framer-motion";
import type { ComponentProps } from "react";
import { fired } from "../Fire";
import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { watered } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      red,
      gray,
      green
    }
  }
} = tailwindConfig;
export const Flower = ({
  className,
  variants,
  variantsLeaves,
  variantsPetals,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  immediate?: boolean;
  variantsLeaves?: Variants;
  variantsPetals?: Variants;
}) => <motion.div className={`pointer-events-none relative ${sizes.blockContent.className} ${className}`} data-sentry-element="unknown" data-sentry-component="Flower" data-sentry-source-file="index.tsx">
    {!immediate && <Sprinkle className="absolute top-0" variantName="sun" />}
    <MotionDiv {...props} className={`absolute top-0 ${sizes.blockContent.className}`} variants={{
    inactive: {
      scale: 1
    },
    disactive: {
      scale: 1
    },
    unfilled: {
      scale: 1
    },
    fire: {
      scale: 1
    },
    water: {
      scale: 1
    },
    sun: {
      scale: [1, 2, 1.8, 1],
      transition: immediate ? {
        delay: 0,
        duration: 0
      } : {
        delay: 0.15,
        duration: 0.4,
        bounce: true
      }
    },
    ...variants
  }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
      <motion.div className="absolute top-0 h-16 overflow-hidden" style={{
      clipPath: `circle(1.9rem at 50% 50%)`
    }} variants={{
      ...variantsPetals,
      inactive: {
        opacity: 0.5,
        color: gray[100],
        rotate: "0deg",
        ...variantsPetals?.inactive
      },
      disactive: {
        opacity: 0.75,
        color: gray[100],
        rotate: "0deg",
        ...variantsPetals?.disactive
      },
      unfilled: {
        opacity: 1,
        color: gray[100],
        rotate: "0deg",
        ...variantsPetals?.unfilled
      },
      fire: {
        opacity: 1,
        color: fired,
        rotate: "0deg",
        ...variantsPetals?.fire
      },
      water: {
        opacity: 1,
        color: watered,
        rotate: "0deg",
        ...variantsPetals?.water
      },
      sun: {
        opacity: 1,
        color: red[500],
        transition: {
          delay: immediate ? 0 : 0.15,
          duration: 0
        },
        ...variantsPetals?.sun
      },
      animating: {
        rotate: "360deg",
        transition: {
          delay: immediate ? 0 : 0.75,
          duration: 10,
          repeat: Infinity,
          ease: "linear"
        },
        ...variantsPetals?.animating
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <LocalFloristRounded style={{
        height: `${sizes.blockContent.rem}rem`,
        width: `${sizes.blockContent.rem}rem`
      }} data-sentry-element="LocalFloristRounded" data-sentry-source-file="index.tsx" />
      </motion.div>
      <motion.div className="absolute bottom-0 h-11 overflow-hidden" style={{
      clipPath: `polygon(0% 0%, 35% 0, 50% 50%, 65% 0, 100% 0%, 100% 100%, 0% 100%)`
    }} variants={{
      ...variantsLeaves,
      inactive: {
        opacity: 0.5,
        color: gray[100],
        ...variantsLeaves?.inactive
      },
      disactive: {
        opacity: 0.75,
        color: gray[100],
        ...variantsLeaves?.disactive
      },
      unfilled: {
        opacity: 1,
        color: gray[100],
        ...variantsLeaves?.unfilled
      },
      fire: {
        opacity: 1,
        color: fired,
        ...variantsLeaves?.fire
      },
      water: {
        opacity: 1,
        color: watered,
        ...variantsLeaves?.water
      },
      sun: {
        opacity: 1,
        color: green[700],
        transition: {
          delay: immediate ? 0 : 0.15,
          duration: 0
        },
        ...variantsLeaves?.sun
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <LocalFloristRounded className="-translate-y-[3.25rem]" style={{
        height: `${sizes.blockContent.rem}rem`,
        width: `${sizes.blockContent.rem}rem`
      }} data-sentry-element="LocalFloristRounded" data-sentry-source-file="index.tsx" />
      </motion.div>
    </MotionDiv>
  </motion.div>;