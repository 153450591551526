globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"44e711838c524e1e0928197f867c99bc10480453"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

import { NEXT_PUBLIC_SENTRY_DSN } from "src/sentry/env";
import { VERCEL_GIT_COMMIT_SHA } from "src/vercel/env";

Sentry.init({
  release: `sunblocks/web@${VERCEL_GIT_COMMIT_SHA}`,
  enabled: process.env.NODE_ENV === "production",

  dsn: NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: process.env.NODE_ENV !== "production" ? 0 : 1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.NODE_ENV !== "production" ? 0 : 0.1,

  integrations: [
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      showBranding: false,
      triggerLabel: "Feedback",
      formTitle: "Share your Feedback!",
      submitButtonLabel: "Share your Feedback!",
      messagePlaceholder:
        'What do you think of Sun Blocks? Please give feedback! Or, if something broke, what happened? Please click "Add a screenshot", it helps me a lot!',
    }),
    // TODO Add Sentry.thirdPartyErrorFilterIntegration, fixes SUNBLOCKS-WEB-15 https://github.com/getsentry/sentry-docs/issues/11213
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    posthog.sentryIntegration({
      organization: "sunblocks",
      projectId: 4507768341397504,
    }),
    ...(process.env.NODE_ENV !== "production"
      ? []
      : [Sentry.debugIntegration()]),
  ],

  beforeSend:
    process.env.NODE_ENV !== "production"
      ? undefined
      : (event) => {
          if (event.exception && event.event_id) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }

          return event;
        },
});
