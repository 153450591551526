import {
  getActiveByKey,
  getActiveNodes,
  getAdjacentBlocksByKey,
  getAdjacentNodesByKey,
  getBlockByPosition,
  getBlockPositionByKey,
  getCellByPosition,
  getFillByKey,
  getFillsByKey,
  getNodesByFill,
  getSinksByFill,
  isWin,
} from "./logic";

const getBlockByPositionLazyInner = <Other>(
  level: Parameters<typeof getBlockByPosition>[0] &
    Parameters<typeof getBlockPositionByKey>[0],
  other: Partial<Other & Parameters<typeof getBlockByPosition>[1]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withBlockPositionByKey = {
    ...other,
    blockPositionByKey:
      other.blockPositionByKey ?? getBlockPositionByKey(level, moves),
  };

  return {
    ...withBlockPositionByKey,
    blockByPosition: getBlockByPosition(level, withBlockPositionByKey),
  };
};

export const getBlockByPositionLazy = <Other>(
  ...args: Parameters<typeof getBlockByPositionLazyInner<Other>>
) => getBlockByPositionLazyInner(...args).blockByPosition;

const getAdjacentBlocksByKeyLazyInner = <Other>(
  level: Parameters<typeof getAdjacentBlocksByKey>[0] &
    Parameters<typeof getBlockByPositionLazyInner>[0] &
    Parameters<typeof getBlockPositionByKey>[0],
  other: Partial<Other & Parameters<typeof getAdjacentBlocksByKey>[1]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withBlockPositionByKey = {
    ...other,
    blockPositionByKey:
      other.blockPositionByKey ?? getBlockPositionByKey(level, moves),
  };

  const withBlockByPosition = {
    ...withBlockPositionByKey,
    ...(withBlockPositionByKey.blockByPosition
      ? { blockByPosition: withBlockPositionByKey.blockByPosition }
      : getBlockByPositionLazyInner(level, withBlockPositionByKey, moves)),
  };

  return {
    ...withBlockByPosition,
    adjacentBlocksByKey: getAdjacentBlocksByKey(level, withBlockByPosition),
  };
};

export const getAdjacentBlocksByKeyLazy = <Other>(
  ...args: Parameters<typeof getAdjacentBlocksByKeyLazyInner<Other>>
) => getAdjacentBlocksByKeyLazyInner(...args).adjacentBlocksByKey;

const getAdjacentNodesByKeyLazyInner = <Other>(
  level: Parameters<typeof getAdjacentBlocksByKeyLazyInner>[0] &
    Parameters<typeof getAdjacentNodesByKey>[0] &
    Parameters<typeof getBlockByPositionLazyInner>[0] &
    Parameters<typeof getBlockPositionByKey>[0] &
    Parameters<typeof getCellByPosition>[0],
  other: Partial<Other & Parameters<typeof getAdjacentNodesByKey>[1]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withBlockPositionByKey = {
    ...other,
    blockPositionByKey:
      other.blockPositionByKey ?? getBlockPositionByKey(level, moves),
  };

  const withBlockByPosition = {
    ...withBlockPositionByKey,
    ...(withBlockPositionByKey.blockByPosition
      ? { blockByPosition: withBlockPositionByKey.blockByPosition }
      : getBlockByPositionLazyInner(level, withBlockPositionByKey, moves)),
  };

  const withAdjacentBlocksByKey = {
    ...withBlockByPosition,
    ...(withBlockByPosition.adjacentBlocksByKey
      ? { adjacentBlocksByKey: withBlockByPosition.adjacentBlocksByKey }
      : getAdjacentBlocksByKeyLazyInner(level, withBlockByPosition, moves)),
  };

  const withCellByPosition = {
    ...withAdjacentBlocksByKey,
    cellByPosition:
      withAdjacentBlocksByKey.cellByPosition ?? getCellByPosition(level),
  };

  return {
    ...withCellByPosition,
    adjacentNodesByKey: getAdjacentNodesByKey(level, withCellByPosition),
  };
};

const getActiveByKeyLazyInner = <Other>(
  level: Parameters<typeof getActiveByKey>[0] &
    Parameters<typeof getAdjacentBlocksByKeyLazyInner>[0] &
    Parameters<typeof getAdjacentNodesByKeyLazyInner>[0],
  other: Parameters<typeof getAdjacentBlocksByKeyLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof getActiveByKey>[1]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withAdjacentBlocksByKey = {
    ...other,
    ...(other.adjacentBlocksByKey
      ? { adjacentBlocksByKey: other.adjacentBlocksByKey }
      : getAdjacentBlocksByKeyLazyInner(level, other, moves)),
  };

  const withAdjacentNodesByKey = {
    ...withAdjacentBlocksByKey,
    ...(withAdjacentBlocksByKey.adjacentNodesByKey
      ? { adjacentNodesByKey: withAdjacentBlocksByKey.adjacentNodesByKey }
      : getAdjacentNodesByKeyLazyInner(level, withAdjacentBlocksByKey, moves)),
  };

  return {
    ...withAdjacentNodesByKey,
    activeByKey: getActiveByKey(level, withAdjacentNodesByKey),
  };
};

const getActiveNodesLazyInner = <Other>(
  level: Parameters<typeof getActiveByKeyLazyInner>[0] &
    Parameters<typeof getActiveNodes>[0],
  other: Parameters<typeof getActiveByKeyLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof getActiveNodes>[1]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withActiveByKey = {
    ...other,
    ...(other.activeByKey
      ? { activeByKey: other.activeByKey }
      : getActiveByKeyLazyInner(level, other, moves)),
  };

  return {
    ...withActiveByKey,
    activeNodes: getActiveNodes(level, withActiveByKey),
  };
};

const getFillsByKeyLazyInner = <Other>(
  level: Parameters<typeof getActiveByKeyLazyInner>[0] &
    Parameters<typeof getActiveNodesLazyInner>[0] &
    Parameters<typeof getAdjacentNodesByKeyLazyInner>[0],
  other: Parameters<typeof getActiveByKeyLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof getFillsByKey>[0]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withActiveByKey = {
    ...other,
    ...(other.activeByKey
      ? { activeByKey: other.activeByKey }
      : getActiveByKeyLazyInner(level, other, moves)),
  };

  const withActiveNodes = {
    ...withActiveByKey,
    ...(withActiveByKey.activeNodes
      ? { activeNodes: withActiveByKey.activeNodes }
      : getActiveNodesLazyInner(level, withActiveByKey, moves)),
  };

  const withAdjacentNodesByKey = {
    ...withActiveNodes,
    ...(withActiveNodes.adjacentNodesByKey
      ? { adjacentNodesByKey: withActiveNodes.adjacentNodesByKey }
      : getAdjacentNodesByKeyLazyInner(level, withActiveNodes, moves)),
  };
  return {
    ...withAdjacentNodesByKey,
    fillsByKey: getFillsByKey(withAdjacentNodesByKey),
  };
};

const getFillByKeyLazyInner = <Other>(
  level: Parameters<typeof getFillsByKeyLazyInner>[0],
  other: Parameters<typeof getFillsByKeyLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof getFillByKey>[0]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withFillsByKey = {
    ...other,
    ...(other.fillsByKey
      ? { fillsByKey: other.fillsByKey }
      : getFillsByKeyLazyInner(level, other, moves)),
  };

  return {
    ...withFillsByKey,
    fillByKey: getFillByKey(withFillsByKey),
  };
};

const getNodesByFillLazyInner = <Other>(
  level: Parameters<typeof getActiveNodesLazyInner>[0] &
    Parameters<typeof getFillByKeyLazyInner>[0],
  other: Parameters<typeof getActiveNodesLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof getNodesByFill>[0]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withActiveNodes = {
    ...other,
    ...(other.activeNodes
      ? { activeNodes: other.activeNodes }
      : getActiveNodesLazyInner(level, other, moves)),
  };

  const withFillByKey = {
    ...withActiveNodes,
    ...(withActiveNodes.fillByKey
      ? { fillByKey: withActiveNodes.fillByKey }
      : getFillByKeyLazyInner(level, withActiveNodes, moves)),
  };

  return {
    ...withFillByKey,
    nodesByFill: getNodesByFill(withFillByKey),
  };
};

export const getNodesByFillLazy = <Other>(
  ...args: Parameters<typeof getNodesByFillLazyInner<Other>>
) => getNodesByFillLazyInner(...args).nodesByFill;

const isWinLazyInner = <Other>(
  level: Parameters<typeof getNodesByFillLazyInner>[0] &
    Parameters<typeof getSinksByFill>[0],
  other: Parameters<typeof getNodesByFillLazyInner<Other>>[1] &
    Partial<Other & Parameters<typeof isWin>[0]> = {},
  moves: Parameters<typeof getBlockPositionByKey>[1] = []
) => {
  const withNodesByFill = {
    ...other,
    ...(other.nodesByFill
      ? { nodesByFill: other.nodesByFill }
      : getNodesByFillLazyInner(level, other, moves)),
  };

  const withSinksByFill = {
    ...withNodesByFill,
    sinksByFill: withNodesByFill.sinksByFill ?? getSinksByFill(level),
  };

  return {
    ...withSinksByFill,
    isWin: isWin(withSinksByFill),
  };
};

export const isWinLazy = <Other>(
  ...args: Parameters<typeof isWinLazyInner<Other>>
) => isWinLazyInner(...args).isWin;
