import { sumBy } from "lodash/fp";

export type Position = [y: number, x: number];

export const sumPositions = (...positions: Position[]): Position => [
  sumBy(([y]) => y, positions),
  sumBy(([, x]) => x, positions),
];

export type PositionMap<T> = { [y: number]: { [x: number]: T } };

export const toPositionMap = <T, S>(
  array: T[],
  toPosition: (value: T) => MaybeArray<[...Position, ...any[]]>,
  toValue: (value: T, prevValue: S | undefined) => S
) =>
  array.reduce((positionObj, value) => {
    const position = toPosition(value);

    return (
      Array.isArray(position[0])
        ? (position as Position[])
        : [position as Position]
    ).reduce(
      (positionObj, [y, x]) => ({
        ...positionObj,
        [y]: {
          ...positionObj[y],
          [x]: toValue(value, positionObj[y]?.[x]),
        },
      }),
      positionObj
    );
  }, {} as PositionMap<S>);
