import Brightness7Rounded from "@mui/icons-material/Brightness7Rounded";
import type { ComponentProps } from "react";
import { fired } from "../Fire";
import { MotionDiv } from "../Motion";
import { watered } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
const {
  theme: {
    colors: {
      orange
    }
  }
} = tailwindConfig;

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const sunScale = 1.100001;
export const Sun = ({
  className,
  variants,
  ...props
}: ComponentProps<typeof MotionDiv>) => <MotionDiv {...props} className={`pointer-events-none ${sizes.blockContent.className} ${className}`} variants={{
  ...variants,
  inactive: {
    opacity: 0.5,
    color: orange[500],
    rotate: "0deg",
    ...variants?.inactive
  },
  disactive: {
    opacity: 1,
    color: orange[500],
    rotate: "0deg",
    ...variants?.disactive
  },
  unfilled: {
    opacity: 1,
    color: orange[500],
    rotate: "0deg",
    ...variants?.unfilled
  },
  fire: {
    opacity: 1,
    color: fired,
    rotate: "0deg",
    ...variants?.fire
  },
  water: {
    opacity: 1,
    color: watered,
    rotate: "0deg",
    ...variants?.water
  },
  sun: {
    opacity: 1,
    color: orange[500],
    ...variants?.sun
  },
  animating: {
    rotate: "360deg",
    transition: {
      rotate: {
        duration: 10,
        repeat: Infinity,
        ease: "linear"
      }
    },
    ...variants?.animating
  }
}} data-sentry-element="MotionDiv" data-sentry-component="Sun" data-sentry-source-file="index.tsx">
    <Brightness7Rounded style={{
    height: `${sizes.blockContent.rem}rem`,
    width: `${sizes.blockContent.rem}rem`
  }} data-sentry-element="Brightness7Rounded" data-sentry-source-file="index.tsx" />
  </MotionDiv>;