import { ObjectId } from "mongodb/lib/bson";
import { z } from "zod";

import { zMove } from "@sunblocks/game";

import { createStorage } from "./use-storage";

const {
  StorageProvider: LocalStorage,
  useScopedStorage: useScopedLocalStorage,
} = createStorage(
  () => localStorage,
  "sunblocks",
  z.object({
    lastLevelOpened: z.optional(z.instanceof(ObjectId)),
    levels: z.optional(
      z.record(
        z.string(),
        z.object({
          bestScore: z.optional(z.number()),
          inProgress: z.optional(z.tuple([z.array(zMove), z.array(zMove)])),
          rev: z.string(),
          overallMoves: z.optional(z.number()),
          timeInLevel: z.optional(z.number()),
        })
      )
    ),
    muted: z.optional(z.boolean()),
  })
);

export { LocalStorage, useScopedLocalStorage };
