import { motion, useMotionValueEvent, useScroll, useSpring, useTransform } from "framer-motion";
import type { ObjectId } from "mongodb/lib/bson";
import Link from "next/link";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isConditionMet, resolveModels } from "@sunblocks/game";
import type { Area, Level } from "@sunblocks/game";
import { useBackground } from "../Background";
import { BlockModel } from "../BlockModel";
import { CellModel } from "../CellModel";
import { MotionDiv } from "../Motion";
import { sizes } from "../sizes";
import { useRemPx } from "../use-rem-px";
import { useSounds } from "../use-sound";
import { useWindowDimensions } from "../use-window-dimensions";
type TrimmedLevel = Pick<Level, "_id" | "background" | "condition" | "models">;
type TrimmedArea = Pick<Area, "_id" | "background" | "models"> & {
  levels: TrimmedLevel[];
};
const indexOr = (index: number, ...or: (number | (() => number))[]): number => index !== -1 || !or.length ? index : indexOr(typeof or[0]! !== "number" ? or[0]!() : or[0]!, ...or.slice(1));
export const Levels = ({
  areas,
  getLevelUrl,
  lastLevelOpened,
  onAnimateDone,
  onAnimateLevelState,
  onHiddenDone,
  onPickedLevel,
  bestScores = {},
  immediate = false,
  personalBestScores = {},
  previousBestScores = {},
  sounds = true
}: {
  areas: TrimmedArea[];
  bestScores?: {
    [levelId: string]: number;
  };
  getLevelUrl: (level: TrimmedLevel) => string;
  immediate?: boolean;
  lastLevelOpened?: ObjectId;
  onAnimateDone?: () => void;
  onAnimateLevelState?: (levelId: ObjectId) => void;
  onHiddenDone?: (pickedLevel: TrimmedLevel) => void;
  onPickedLevel?: (pickedLevel: TrimmedLevel) => void;
  personalBestScores?: {
    [levelId: string]: number;
  };
  previousBestScores?: {
    [levelId: string]: number | undefined;
  };
  sounds?: boolean;
}) => {
  const choseModel = useCallback(({
    _id,
    condition
  }: TrimmedLevel, personalBestScores: {
    [levelId: string]: number | undefined;
  }) => !isConditionMet(condition, personalBestScores) ? "locked" : !personalBestScores[`${_id}`] ? "available" : (bestScores[`${_id}`] ?? Infinity) < (personalBestScores[`${_id}`] ?? 0) ? "won" : "wonBest", [bestScores]);
  const models = useMemo(() => areas.map(({
    levels,
    models: areaModels
  }) => {
    const levelModels = levels.map(level => {
      const chosen = choseModel(level, personalBestScores);
      return {
        chosen: chosen as typeof chosen,
        model: resolveModels(chosen, areaModels, level.models)
      };
    });
    return {
      levels: levelModels,
      hide: levelModels.every(({
        chosen
      }) => chosen === "locked")
    };
  }), [areas, choseModel, personalBestScores]);
  const areaSizes = useMemo(() => areas.map(({
    levels,
    models: areaModels
  }) => {
    const height = Math.ceil(Math.sqrt(levels.length));
    const hopeful = Math.floor(Math.sqrt(levels.length));
    const width = height * hopeful >= levels.length ? hopeful : hopeful + 1;
    const anyAreCell = levels.some(({
      models: levelModels
    }) => resolveModels("available", areaModels, levelModels).model?.cell);
    return {
      anyAreCell,
      height,
      width,
      heightRem: height * (anyAreCell ? sizes.cell.rem + sizes.distanceBetween.rem / 0.75 : sizes.block.rem + sizes.distanceBetween.rem / 2),
      widthRem: width * (anyAreCell ? sizes.cell.rem + sizes.distanceBetween.rem / 0.75 : sizes.block.rem + sizes.distanceBetween.rem / 2)
    };
  }), [areas]);
  const [previousModels] = useState(() => immediate ? models : areas.map(({
    levels,
    models: areaModels
  }) => {
    const levelModels = levels.map(level => {
      const chosen = choseModel(level, {
        ...personalBestScores,
        ...previousBestScores
      });
      return {
        chosen: chosen as typeof chosen,
        model: resolveModels(chosen, areaModels, level.models)
      };
    });
    return {
      levels: levelModels,
      hide: levelModels.every(({
        chosen
      }) => chosen === "locked")
    };
  }));
  const renderedModels = useMemo(() => models.map(({
    hide
  }, index) => !hide || !previousModels[index]?.hide), [models, previousModels]);
  const windowDimensions = useWindowDimensions();
  const scale = useMemo(() => Math.min(1, (windowDimensions.height / 16 - 2 * sizes.menu.rem - 2 * sizes.distanceBetween.rem) / Math.max(...areaSizes.filter((_, index) => renderedModels[index]).map(({
    heightRem
  }) => heightRem)), (windowDimensions.width / 16 - 2 * sizes.distanceBetween.rem) / Math.max(...areaSizes.filter((_, index) => renderedModels[index]).map(({
    widthRem
  }) => widthRem))), [areaSizes, renderedModels, windowDimensions.height, windowDimensions.width]);
  const remPx = useRemPx(scale);
  const gapSizes = useMemo(() => areaSizes.map(({
    anyAreCell,
    height,
    heightRem
  }) => ({
    gapRem: Math.max(0, (windowDimensions.height / remPx - heightRem) / 2),
    neighborGapRem: Math.max(sizes.block.rem, (windowDimensions.height / remPx - (height + 1) * (anyAreCell ? sizes.cell.rem + sizes.distanceBetween.rem / 0.75 : sizes.block.rem + sizes.distanceBetween.rem / 2)) / 2)
  })), [areaSizes, remPx, windowDimensions.height]);
  const [ready, setReady] = useState(immediate);

  // For all of these, -1 is the start, and the length is the end
  const [animatingAreaIndex, setAnimatingAreaIndex] = useState(immediate ? models.length : -1);
  const [animatingLevelIndex, setAnimatingLevelIndex] = useState(-1);
  const [currentAreaIndex, setCurrentAreaIndex] = useState(-1);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(-1);
  const doneAnimating = useMemo(() => animatingAreaIndex === models.length, [animatingAreaIndex, models.length]);
  const scrollStops = useMemo(() => renderedModels.reduce((acc, rendered, index) => {
    const previous = acc.at(-1) ?? {
      index: 0,
      scrollPositionPx: 0
    };
    return [...acc, !rendered ? previous : {
      index,
      gapSize: gapSizes[index]!,
      scrollPositionPx: previous.scrollPositionPx + (!previous.gapSize ? 0 : windowDimensions.height + remPx * (-previous.gapSize.gapRem - gapSizes[index]!.gapRem + Math.min(previous.gapSize.neighborGapRem, gapSizes[index]!.neighborGapRem)))
    }];
  }, [] as {
    gapSize?: (typeof gapSizes)[number];
    index: number;
    scrollPositionPx: number;
  }[]), [gapSizes, remPx, renderedModels, windowDimensions.height]);
  const scrollPositionsPx = useMemo(() => scrollStops.map(({
    scrollPositionPx
  }) => scrollPositionPx), [scrollStops]);
  const [scrollToAreaIndex, setScrollToAreaIndex] = useState(-1);
  const scrollParent = useRef<HTMLDivElement>(null);
  const scrollDuration = 1000;
  const scrollToYPx = useSpring(0, {
    duration: scrollDuration
  });
  useMotionValueEvent(scrollToYPx, "change", value => {
    scrollParent.current!.scrollTop = value;
  });
  const scrollChild = useRef<HTMLDivElement>(null);
  const {
    scrollY
  } = useScroll({
    container: scrollParent,
    target: scrollChild
  });
  const scrolledAreaIndexFloat = useTransform(scrollY, scrollPositionsPx, scrollStops.map(({
    index
  }) => index));
  const scrolledAreaIndexMotionValue = useTransform(() => Math.round(scrolledAreaIndexFloat.get()));
  const [scrolledAreaIndex, setScrolledAreaIndex] = useState(-1);
  useMotionValueEvent(scrolledAreaIndexMotionValue, "change", setScrolledAreaIndex);
  useEffect(() => {
    if (scrolledAreaIndex !== -1 || scrollPositionsPx.some(scrollPositionPx => scrollPositionPx < 0)) {
      return;
    }
    const initialScrolledAreaIndex = indexOr(models.findIndex(({
      hide,
      levels
    }, areaIndex) => hide !== previousModels[areaIndex]?.hide || levels.some(({
      chosen
    }, levelIndex) => chosen !== previousModels[areaIndex]?.levels[levelIndex]?.chosen)), () => areas.findIndex(({
      levels
    }) => levels.some(({
      _id
    }) => _id.equals(lastLevelOpened))), () => models.findIndex(({
      levels
    }) => levels.some(({
      chosen
    }) => chosen === "available")), models.length);
    const newInitialScrolledY = initialScrolledAreaIndex === models.length ? 0 : scrollPositionsPx[initialScrolledAreaIndex]!;
    setScrolledAreaIndex(initialScrolledAreaIndex);
    scrollParent.current!.scrollTop = newInitialScrolledY;
    scrollToYPx.setCurrent(newInitialScrolledY);
    scrollY.set(newInitialScrolledY);
  }, [areas, lastLevelOpened, models, previousModels, scrolledAreaIndex, scrollPositionsPx, scrollToYPx, scrollY]);
  const [pickedArea, setPickedArea] = useState<TrimmedArea>();
  const [pickedLevel, setPickedLevel] = useState<TrimmedLevel>();
  useBackground(pickedLevel?.background ?? pickedArea?.background ?? areas[scrolledAreaIndex]?.background, {
    immediate: immediate || scrolledAreaIndex === -1
  });
  const {
    playBlockSun,
    playCellMove,
    playCellSun,
    playLevelAvailable,
    playLevelDoneFull,
    playNActive,
    playNodeFire,
    playNodeWater,
    playSinkSatisfiedWater,
    playWeakActive
  } = useSounds(sounds);
  useEffect(() => {
    if (!ready || animatingAreaIndex !== currentAreaIndex || animatingLevelIndex !== currentLevelIndex) {
      return;
    }
    let newAreaIndex = animatingAreaIndex;
    if (animatingAreaIndex === -1 || animatingLevelIndex === models[animatingAreaIndex]!.levels.length) {
      newAreaIndex = indexOr(models.findIndex(({
        hide,
        levels
      }, areaIndex) => animatingAreaIndex < areaIndex && (hide !== previousModels[areaIndex]?.hide || levels.some(({
        chosen
      }, levelIndex) => chosen !== previousModels[areaIndex]?.levels[levelIndex]?.chosen))), models.length);
      setAnimatingAreaIndex(newAreaIndex);
      if (animatingAreaIndex !== models.length && newAreaIndex === models.length) {
        onAnimateDone?.();
      }

      // There's an annoying rounding thing going on, so we do this instead
      const basicallyScrolledTo = Math.abs((scrollParent.current?.scrollTop ?? 0) - scrollPositionsPx[newAreaIndex]!) < 1;
      if (newAreaIndex === models.length || models[newAreaIndex]?.hide !== previousModels[newAreaIndex]?.hide || !basicallyScrolledTo) {
        setAnimatingLevelIndex(-1);
        setCurrentLevelIndex(-1);
        if (newAreaIndex !== models.length && !basicallyScrolledTo) {
          scrollToYPx.set(scrollPositionsPx[newAreaIndex]!);
          setTimeout(() => {
            setScrollToAreaIndex(newAreaIndex);
            if (models[newAreaIndex]?.hide !== previousModels[newAreaIndex]?.hide) {
              return;
            }
            setCurrentAreaIndex(newAreaIndex);
          }, scrollDuration);
        }
        return;
      }
      setCurrentAreaIndex(newAreaIndex);
    }
    const newLevelIndex = indexOr(models[newAreaIndex]!.levels.findIndex(({
      chosen
    }, levelIndex) => animatingLevelIndex < levelIndex && chosen !== previousModels[newAreaIndex]?.levels[levelIndex]?.chosen), models[newAreaIndex]!.levels.length);
    setAnimatingLevelIndex(newLevelIndex);
    if (newLevelIndex === models[newAreaIndex]!.levels.length) {
      setCurrentLevelIndex(newLevelIndex);
      return;
    }
    const {
      chosen,
      model: {
        fill,
        model = {
          cell: false
        }
      }
    } = models[newAreaIndex]!.levels[newLevelIndex]!;
    switch (chosen) {
      case "wonBest":
        {
          if (fill === "water") {
            playSinkSatisfiedWater();
          } else {
            playLevelDoneFull();
          }
          break;
        }
      case "won":
        {
          if (fill === "water") {
            playNodeWater();
          } else {
            // TODO Unfilled night sound being sun is weird
            (model.cell ? playCellSun : playBlockSun)[(newLevelIndex + 32 - areas[newAreaIndex]!.levels.length) % 16]?.();
          }
          break;
        }
      case "available":
        {
          if ("n" in model) {
            playNActive();
          } else if ("weak" in model && model.weak) {
            playWeakActive();
          } else if (fill === "fire") {
            playNodeFire();
          } else {
            playLevelAvailable();
          }
          break;
        }
      default:
        {
          break;
        }
    }

    // Clearing this ends everything so...?
    setTimeout(() => {
      setCurrentLevelIndex(newLevelIndex);
      onAnimateLevelState?.(areas[newAreaIndex]!.levels[newLevelIndex]!._id);
    }, chosen === "wonBest" ? 750 : chosen === "won" ? 500 : 250);
  }, [animatingAreaIndex, animatingLevelIndex, areas, currentAreaIndex, currentLevelIndex, models, onAnimateDone, onAnimateLevelState, playBlockSun, playCellSun, playLevelAvailable, playLevelDoneFull, playNActive, playNodeFire, playNodeWater, playSinkSatisfiedWater, playWeakActive, previousModels, ready, scrollPositionsPx, scrollToYPx]);
  return <MotionDiv ref={scrollParent} className={`flex h-full w-full flex-col items-center ${!pickedLevel && ready && doneAnimating ? "overflow-y-scroll" : "pointer-events-none overflow-y-hidden"}`} style={{
    height: windowDimensions.height,
    width: windowDimensions.width
  }} initial={immediate && !pickedLevel ? "visible" : "hidden"} animate={!pickedLevel && scrolledAreaIndex !== -1 ? "visible" : "hidden"} onAnimationComplete={{
    visible: () => setReady(true),
    hidden: pickedLevel && (() => onHiddenDone?.(pickedLevel))
  }} data-sentry-element="MotionDiv" data-sentry-component="Levels" data-sentry-source-file="index.tsx">
      <div ref={scrollChild} className="shrink-0" style={{
      height: `${gapSizes[0]!.gapRem}rem`
    }} />
      {areas.map(({
      _id,
      levels
    }, areaIndex) => {
      const area = areas[areaIndex]!;
      const {
        hide: previousHide
      } = previousModels[areaIndex]!;
      const {
        hide
      } = models[areaIndex]!;
      const {
        anyAreCell,
        height,
        width
      } = areaSizes[areaIndex]!;
      const {
        gapRem,
        neighborGapRem
      } = gapSizes[areaIndex]!;
      const nextVisibleAreaIndex = renderedModels.findIndex((rendered, nextAreaIndex) => areaIndex < nextAreaIndex && rendered);
      return !renderedModels[areaIndex] ? null : <Fragment key={`${_id}`}>
            <motion.div className="flex flex-col items-center justify-center" {...hide === previousHide ? {} : {
          initial: "start",
          animate: pickedLevel ? "hidden" : animatingAreaIndex >= areaIndex && scrollToAreaIndex >= areaIndex ? "visible" : "start",
          onAnimationComplete: definition => {
            if (definition !== "visible") {
              return;
            }
            setCurrentAreaIndex(areaIndex);
          }
        }}>
              {Array.from({
            length: height
          }).map((_, y) => <div key={y} className="flex flex-row">
                  {levels.slice(y * width, (y + 1) * width).map((level, x) => {
              const levelIndex = y * width + x;
              const {
                chosen,
                model: {
                  active,
                  fill,
                  model,
                  nCount,
                  night
                }
              } = (areaIndex < animatingAreaIndex || areaIndex === animatingAreaIndex && levelIndex <= animatingLevelIndex ? models : previousModels)[areaIndex]!.levels[levelIndex]!;
              return <MotionDiv key={x} className={areaIndex === animatingAreaIndex && levelIndex === animatingLevelIndex ? "z-30" : chosen === "locked" || !ready ? "" : pickedLevel === level ? "z-20" : "focus-within:z-10 hover:z-10"} style={{
                opacity: 0,
                translateX: "100%"
              }} variants={{
                visible: {
                  opacity: [0, 1],
                  translateX: areaIndex !== scrolledAreaIndex ? 0 : ["100%", 0],
                  transition: immediate ? {
                    delay: 0,
                    duration: 0
                  } : {
                    delay: areaIndex !== scrolledAreaIndex ? 0.1 : (y + x) * 0.1,
                    duration: areaIndex !== scrolledAreaIndex ? 1.5 : 0.5,
                    type: "spring"
                  }
                },
                hidden: {
                  opacity: [1, 0],
                  translateX: areaIndex !== scrolledAreaIndex ? 0 : [0, "-100%"],
                  transition: {
                    delay: areaIndex !== scrolledAreaIndex ? 0.1 : pickedLevel === level ? (width + height) * 0.1 + 1 : (y + x) * 0.1,
                    duration: areaIndex !== scrolledAreaIndex ? 1.5 : 0.5,
                    type: "spring"
                  }
                }
              }} onAnimationStartDelayed={areaIndex !== scrolledAreaIndex ? {} : {
                visible: () => playCellMove(),
                hidden: () => playCellMove()
              }}>
                        <motion.div className={`flex flex-col items-center justify-center drop-shadow-lg transition-transform ${chosen === "locked" ? "opacity-50" : !ready ? "" : pickedLevel === level ? "scale-110" : "focus-within:scale-110 focus-within:drop-shadow-xl hover:scale-110 hover:drop-shadow-xl "} ${anyAreCell ? sizes.cell.className : sizes.block.className}`} style={{
                  margin: `${sizes.distanceBetween.rem / (anyAreCell ? 1.5 : 4)}rem`
                }} initial="visible">
                          <Link tabIndex={chosen === "locked" ? -1 : 0} className={`outline-offset-8 outline-blue-500  ${ready && chosen !== "locked" ? "" : "cursor-not-allowed"}`} href={getLevelUrl(level)} onClick={event => {
                    if (event.ctrlKey || event.metaKey) {
                      return;
                    }
                    event.preventDefault();
                    if (chosen === "locked" || pickedLevel || pickedArea) {
                      return;
                    }
                    setPickedLevel(level);
                    setPickedArea(area);
                    onPickedLevel?.(level);
                  }}>
                            {model?.cell ? <CellModel active={active} animating={doneAnimating} cell={model} fill={fill} sounds={false} immediate={!ready || immediate || areaIndex < animatingAreaIndex || areaIndex === animatingAreaIndex && levelIndex < animatingLevelIndex} /> : <BlockModel active={active} animating={doneAnimating} block={model ?? {}} fill={fill} fillReal={fill} nCount={nCount} night={night} shape={[[0, 0]]} sounds={false} immediate={!ready || immediate || areaIndex < animatingAreaIndex || areaIndex === animatingAreaIndex && levelIndex < animatingLevelIndex} />}
                          </Link>
                        </motion.div>
                      </MotionDiv>;
            })}
                </div>)}
            </motion.div>
            <div className="shrink-0" style={{
          height: `${!gapSizes[nextVisibleAreaIndex] ? gapRem : Math.min(neighborGapRem, gapSizes[nextVisibleAreaIndex]!.neighborGapRem)}rem`
        }} />
          </Fragment>;
    })}
      {animatingAreaIndex !== models.length && <div className="h-[100vh] shrink-0" />}
    </MotionDiv>;
};