import { z } from "zod";

import { createStorage } from "./use-storage";

const {
  StorageProvider: SessionStorage,
  useScopedStorage: useScopedSessionStorage,
  useStorage: useSessionStorage,
} = createStorage(
  () => sessionStorage,
  "sunblocks",
  z.object({
    levels: z.optional(
      z.record(
        z.string(),
        z.optional(
          z.object({
            previousBestScore: z.optional(z.number()),
            rev: z.string(),
          })
        )
      )
    ),
  })
);

export { SessionStorage, useScopedSessionStorage, useSessionStorage };
